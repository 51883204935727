import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'lib-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageViewerComponent {
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() image: string | ArrayBuffer | null = '';
  @Output() loaded: EventEmitter<{
    base64: string | ArrayBuffer | null;
    file: File;
  }> = new EventEmitter<{ base64: string | ArrayBuffer | null; file: File }>();

  constructor() {}

  public handleFileInput(target: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          this.image = reader.result;
          if (this.image) {
            this.loaded.emit({
              base64: this.image,
              file: target.files.item(0),
            });
            resolve();
          } else {
            reject();
          }
        },
        false
      );
      reader.readAsDataURL(target.files.item(0));
    });
  }
}
