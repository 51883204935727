import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CalendarViewType } from '../models/calendar-view-type';

@Component({
  selector: 'lib-calendar-currentTime-bar',
  templateUrl: './calendar-currentTime-bar.component.html',
  styleUrls: ['./calendar-currentTime-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarCurrentTimeBarComponent implements OnInit {
  @Input() viewType: CalendarViewType | string = CalendarViewType.weekly;
  @Input() startTime: number | undefined = 0;
  @Input() timeslotSizeHourInPixel = 20;

  constructor() {}

  ngOnInit(): void {}

  getTop() {
    if (this.startTime) {
      const hours = new Date().getHours() - this.startTime / 60;
      const minutes =
        (new Date().getMinutes() * this.timeslotSizeHourInPixel) / 60;
      return hours * (this.timeslotSizeHourInPixel + 3) + 24 + minutes + 'px';
    } else {
      return '0px';
    }
  }
}
