import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings.component';
import { UserResolver } from '../../../../../api/src/lib/user.resolver';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        resolve: {
          user: UserResolver,
        },
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'services',
        loadChildren: () =>
          import('./services/services.module').then((m) => m.ServicesModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
