import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { IApiResponse } from './models/i-api-response';
import { ICustomer } from './models/i-customer';
import { IPet } from './models/i-pet';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(
    private readonly apiService: ApiService,
    private readonly snackBar: MatSnackBar
  ) {}

  public fetchAll(
    offset?: number,
    limit?: number,
    sort?: Array<string>,
    order?: Array<'asc' | 'desc'>,
    search?: string
  ): Observable<Array<ICustomer>> {
    let queryStringObj = {
      limit: limit ? limit.toString() : '',
      offset: offset ? offset.toString() : '',
      'sort[]': sort ? sort.toString() : '',
      'order[]': order ? order.toString() : '',
    } as any;

    if (!offset) {
      delete queryStringObj.offset;
    }

    if (!sort) {
      delete queryStringObj['sort[]'];
    }

    if (!order) {
      delete queryStringObj['order[]'];
    }

    if (search) {
      queryStringObj = {
        search,
      };
    }

    return this.apiService
      .get('customers?', {
        params: new HttpParams({
          fromObject: queryStringObj,
        }),
      })
      .pipe(
        map((res: IApiResponse) => {
          const customers = res.data as ICustomer[];
          let petsNames = '';

          if (!customers) {
            return [];
          }

          customers.forEach((c) => {
            c.phoneNumber = c.phoneNumber.slice(3, c.phoneNumber.length);
            c.pets.forEach((p, index) => {
              petsNames += p.name + (index < c.pets.length - 1 ? ', ' : '');
            });
            c.petsNames = petsNames;
            petsNames = '';
          });

          return customers;
        })
      );
  }

  public fetchById(id: string): Observable<ICustomer> {
    return this.apiService.get('customers/' + id).pipe(
      map((res: any) => {
        res.phoneNumber = res.phoneNumber.slice(3, res.phoneNumber.length);
        return res as ICustomer;
      })
    );
  }

  public create(customer: ICustomer): Observable<ICustomer> {
    return this.apiService.post('customers', customer).pipe(
      map((res: any) => {
        res.phoneNumber = res.phoneNumber.slice(3, res.phoneNumber.length);
        return res as ICustomer;
      }),
      tap((res) => {
        if (res) {
          this.snackBar.open('Cliente aggiunto', '', {
            duration: 2000,
            panelClass: ['success-api'],
          });
        }
      })
    );
  }

  public addPet(pet: IPet, customerId: string): Observable<ICustomer> {
    const newPet = JSON.parse(JSON.stringify(pet));
    delete newPet.rating;

    newPet.breed = newPet.breed.id;

    return this.apiService
      .post('customers/' + customerId + '/pets', newPet)
      .pipe(
        map((res: any) => {
          const customer = res as ICustomer;
          let petsNames = '';
          customer.phoneNumber = customer.phoneNumber.slice(
            3,
            customer.phoneNumber.length
          );
          customer.pets.forEach((p, index) => {
            petsNames +=
              p.name + (index < customer.pets.length - 1 ? ', ' : '');
          });
          customer.petsNames = petsNames;
          return customer;
        }),
        tap((res) => {
          if (res) {
            this.snackBar.open('Animale aggiunto', '', {
              duration: 2000,
              panelClass: ['success-api'],
            });
          }
        })
      );
  }

  public removePet(customerId: string, petId: string): Observable<ICustomer> {
    return this.apiService
      .delete('customers/' + customerId + '/pets/' + petId)
      .pipe(
        map((res: any) => {
          return res as ICustomer;
        }),
        tap((res) => {
          if (res) {
            this.snackBar.open('Animale rimosso', '', {
              duration: 2000,
              panelClass: ['success-api'],
            });
          }
        })
      );
  }

  public updatePet(pet: IPet, customerId: string): Observable<ICustomer> {
    const newPet = JSON.parse(JSON.stringify(pet));
    delete newPet.rating;
    newPet.breed = newPet.breed.id;

    return this.apiService
      .put('customers/' + customerId + '/pets/' + newPet.id, newPet)
      .pipe(
        map((res: any) => {
          return res as ICustomer;
        }),
        tap((res) => {
          if (res) {
            this.snackBar.open('Animale modificato', '', {
              duration: 2000,
              panelClass: ['success-api'],
            });
          }
        })
      );
  }

  public delete(customer: ICustomer): Observable<ICustomer> {
    if (!customer.id) {
      throw new Error('customer.id is mandatory');
    }

    return this.apiService.delete('customers/' + customer.id).pipe(
      map((res: any) => {
        return res as ICustomer;
      }),
      tap((res) => {
        if (res) {
          this.snackBar.open('Cliente rimosso', '', {
            duration: 2000,
            panelClass: ['success-api'],
          });
        }
      })
    );
  }

  public update(customer: ICustomer): Observable<ICustomer> {
    if (!customer.id) {
      throw new Error('customer.id is mandatory');
    }

    return this.apiService.put('customers/' + customer.id, customer).pipe(
      map((res: any) => {
        res.phoneNumber = res.phoneNumber.slice(3, res.phoneNumber.length);
        return res as ICustomer;
      }),
      tap((res) => {
        if (res) {
          this.snackBar.open('Cliente modificato', '', {
            duration: 2000,
            panelClass: ['success-api'],
          });
        }
      })
    );
  }
}
