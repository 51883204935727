<span *ngIf="show" class="sticky">
    <button mat-flat-button
            *ngIf="!template"
            color="primary"
            class="primary btn-save"
            (click)="save.emit(model)">
      {{'save' | translate}}
    </button>
  <ng-container
    *ngIf="template"
    class="sticky"
    [ngTemplateOutlet]="template"
    [ngTemplateOutletContext]="{ $implicit: templateOpts}">
  </ng-container>
</span>
