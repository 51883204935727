import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { IApiResponse } from './models/i-api-response';
import { map } from 'rxjs/operators';
import { IPetService } from './models/i-pet-service';
import { IPetServiceTier } from './models/i-pet-service-tier';
import { UtilsService } from '../../../utils/src/lib/utils.service';
import { IPetServiceAddon } from './models/i-pet-service-addon';
import { IPetServiceAddonTier } from './models/i-pet-service-addon-tier';
import { environment } from '../../../grooming/src/environments/environment';
import { PetType } from './models/pet-type';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(
    private apiService: ApiService,
    private utilsService: UtilsService
  ) {}

  public fetchAll(petType?: string | PetType): Observable<Array<IPetService>> {
    return this.apiService.get('petServices').pipe(
      map((res: IApiResponse) => {
        const services = res.data as IPetService[];
        services.map((s) => {
          s.tiers.map((t: IPetServiceTier) =>
            Object.assign(t, {
              price: t.price / 100,
              duration: this.utilsService.convertMinutesToHHMM(
                t.duration as number
              ),
            })
          );
          if (s.addons) {
            s.addons.map((a: IPetServiceAddon) => {
              return a.tiers.map((t: IPetServiceAddonTier) => {
                return Object.assign(t, {
                  price: t.price / 100,
                  duration: this.utilsService.convertMinutesToHHMM(
                    t.duration as number
                  ),
                });
              });
            });
          }
          return {
            ...s,
            addons:
              s.addons && s.addons.length === 0 ? delete s.addons : s.addons,
          } as IPetService;
        });
        if (petType) {
          return services.filter((s) => s.petType == petType);
        }
        return services;
      })
    );
  }

  public fetchById(id: string): Observable<IPetService> {
    return this.apiService.get('petServices/' + id).pipe(
      map((res: any) => {
        res.tiers.map((t: IPetServiceTier) =>
          Object.assign(t, {
            price: t.price / 100,
            duration: this.utilsService.convertMinutesToHHMM(
              t.duration as number
            ),
          })
        );
        res.addons.map((a: IPetServiceAddon) => {
          return a.tiers.map((t: IPetServiceAddonTier) => {
            return Object.assign(t, {
              price: t.price / 100,
              duration: this.utilsService.convertMinutesToHHMM(
                t.duration as number
              ),
            });
          });
        });
        return res as IPetService;
      })
    );
  }

  public create(service: IPetService): Observable<IPetService> {
    const serviceTransformed = JSON.parse(JSON.stringify(service));
    serviceTransformed.tiers.map((t: IPetServiceTier) => {
      t.bufferAfter ? t.bufferAfter : delete t.bufferAfter;
      t.bufferBefore ? t.bufferBefore : delete t.bufferBefore;
      return Object.assign(t, {
        price: t.price * 100,
        duration: this.utilsService.convertHHMMtoMinutes(t.duration as string),
      });
    });
    serviceTransformed.addons =
      serviceTransformed.addons && serviceTransformed.addons.length > 0
        ? serviceTransformed.addons
        : [];
    serviceTransformed.tiers[0].bufferAfter
      ? serviceTransformed.tiers[0].bufferAfter
      : delete serviceTransformed.tiers[0].bufferAfter;
    serviceTransformed.tiers[0].bufferBefore
      ? serviceTransformed.tiers[0].bufferBefore
      : delete serviceTransformed.tiers[0].bufferBefore;
    return this.apiService.post('petServices', serviceTransformed).pipe(
      map((res: any) => {
        res.tiers.map((t: IPetServiceTier) =>
          Object.assign(t, {
            price: t.price / 100,
            duration: this.utilsService.convertMinutesToHHMM(
              t.duration as number
            ),
          })
        );
        return res as IPetService;
      })
    );
  }

  public delete(idPetService: string): Observable<IPetService> {
    return this.apiService.delete('petServices/' + idPetService).pipe(
      map((res: any) => {
        return res as IPetService;
      })
    );
  }

  public toggleEnabledAddon(
    petServiceId: string,
    petServiceAddonId: string
  ): Observable<any> {
    if (petServiceAddonId.indexOf('tmp_') > -1) {
      return of(null);
    }
    return this.apiService
      .patch(
        'petServices/' +
          petServiceId +
          '/addons/' +
          petServiceAddonId +
          '/toggleEnabled',
        {}
      )
      .pipe(
        map((res: any) => {
          res.addons && res.addons.length == 0 ? delete res.addons : res.addons;
          res.tiers.map((t: IPetServiceTier) =>
            Object.assign(t, {
              price: t.price / 100,
              duration: this.utilsService.convertMinutesToHHMM(
                t.duration as number
              ),
            })
          );
          return res as IPetServiceAddon;
        })
      );
  }

  public toggleEnabledService(id: string): Observable<any> {
    return this.apiService
      .patch('petServices/' + id + '/toggleEnabled', {})
      .pipe(
        map((res: any) => {
          res.addons && res.addons.length == 0 ? delete res.addons : res.addons;
          res.tiers.map((t: IPetServiceTier) =>
            Object.assign(t, {
              price: t.price / 100,
              duration: this.utilsService.convertMinutesToHHMM(
                t.duration as number
              ),
            })
          );
          return res as IPetService;
        })
      );
  }

  public update(service: IPetService): Observable<IPetService> {
    const newService = JSON.parse(JSON.stringify(service));
    newService.tiers.map((t: IPetServiceTier) => {
      if (t?.id && t.id.indexOf('tmp_') > -1) {
        delete t.id;
      }
      t.bufferAfter ? t.bufferAfter : delete t.bufferAfter;
      t.bufferBefore ? t.bufferBefore : delete t.bufferBefore;
      return Object.assign(t, {
        price: t.price * 100,
        duration: this.utilsService.convertHHMMtoMinutes(t.duration as string),
      });
    });
    newService.addons =
      newService.addons && newService.addons.length > 0
        ? newService.addons
        : [];

    newService.addons.map((a: IPetServiceAddon) => {
      if (a?.id && a.id.indexOf('tmp_') > -1) {
        delete a.id;
      }
      return a.tiers.map((t: IPetServiceAddonTier) => {
        if (t?.id && t.id.indexOf('tmp_') > -1) {
          delete t.id;
        }
        return Object.assign(t, {
          price: t.price * 100,
          duration: this.utilsService.convertHHMMtoMinutes(
            t.duration as string
          ),
        });
      });
    });

    return this.apiService.put('petServices/' + newService.id, newService).pipe(
      map((res: any) => {
        res.addons && res.addons.length == 0 ? delete res.addons : res.addons;
        res.tiers.map((t: IPetServiceTier) =>
          Object.assign(t, {
            price: t.price / 100,
            duration: this.utilsService.convertMinutesToHHMM(
              t.duration as number
            ),
          })
        );
        if (res.addons) {
          res.addons.map((a: IPetServiceAddon) => {
            return a.tiers.map((t: IPetServiceAddonTier) => {
              return Object.assign(t, {
                price: t.price / 100,
                duration: this.utilsService.convertMinutesToHHMM(
                  t.duration as number
                ),
              });
            });
          });
        }
        return res as IPetService;
      })
    );
  }

  computeTotalAmount(
    services: Array<IPetServiceTier | IPetServiceAddonTier> | undefined
  ): string {
    let total = 0;
    if (services) {
      services.forEach((s) => {
        total += s.price;
      });
    }
    return total.toFixed(2);
  }

  computeTotalDuration(
    services: Array<IPetServiceTier | IPetServiceAddonTier> | undefined
  ): number {
    let total = 0;
    if (services) {
      services.forEach((s) => {
        if (s.duration) {
          total += Number(s.duration);
        }
      });
    }
    return total;
  }
}
