<h1 class="title">Impostazioni</h1>
<nav mat-tab-nav-bar dynamicHeight>
  <a mat-tab-link
     class="setting-tab"
     (click)="activateTab('profile');"
     routerLinkActive #rla="routerLinkActive"
     [ngClass]="(selectedPage$ | async) == 'settings/profile' ? 'active' : ''"
     [active]="(selectedPage$ | async) == 'settings/profile'"> Profilo</a>
  <a mat-tab-link
     class="setting-tab"
     (click)="activateTab('invoices');"
     routerLinkActive #rla="routerLinkActive"
     [ngClass]="(selectedPage$ | async) == 'settings/invoices' ? 'active' : ''"
     [active]="(selectedPage$ | async) == 'settings/invoices'"> Fatturazione </a>
  <a mat-tab-link
     class="setting-tab"
     (click)="activateTab('animalsGenders');"
     routerLinkActive #rla="routerLinkActive"
     [ngClass]="(selectedPage$ | async) == 'settings/animalsGenders' ? 'active' : ''"
     [active]="(selectedPage$ | async)  == 'settings/animalsGenders'"> Generi Animali </a>
  <a mat-tab-link
     class="setting-tab"
     (click)="activateTab('services');"
     routerLinkActive #rla="routerLinkActive"
     [ngClass]="(selectedPage$ | async) == 'settings/services' ? 'active' : ''"
     [active]="(selectedPage$ | async) == 'settings/services'"> Servizi </a>
</nav>
<div class="background">
  <router-outlet>
  </router-outlet>
</div>
