import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { IAppointment } from '../../../../../../api/src/lib/models/i-appointment';
import { UtilsService } from '../../../../../../utils/src/lib/utils.service';
import { ICustomer } from '../../../../../../api/src/lib/models/i-customer';
import { debounceTime } from 'rxjs/operators';
import { IPet } from '../../../../../../api/src/lib/models/i-pet';
import { IPetService } from '../../../../../../api/src/lib/models/i-pet-service';
import { IPetServiceAddon } from '../../../../../../api/src/lib/models/i-pet-service-addon';
import { IOperator } from '../../../../../../api/src/lib/models/i-operator';

@Component({
  selector: 'romeo-grooming-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
})
export class AppointmentComponent implements OnInit {
  @Input() appointment$!: Observable<IAppointment | undefined>;
  @Input() newAppointmentForm!: FormGroup;
  @Input() customers$!: Observable<Array<ICustomer>>;
  @Input() services: Array<IPetService> = [];
  @Input() operators: Array<IOperator> = [];
  @Input() tables: Array<any> = [
    { number: '1' },
    { number: '2' },
    { number: '3' },
  ];
  @Input() filters: {
    morning: boolean;
    afternoon: boolean;
  } = {
    morning: true,
    afternoon: true,
  };

  @Output() change: EventEmitter<{ change: any; errors: boolean }> =
    new EventEmitter<{ change: any; errors: boolean }>();
  @Output() onSearchCustomer: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('inputSearchCustomers', { read: ElementRef, static: true })
  public inputSearchCustomers!: ElementRef;
  @ViewChild('inputSearchPet', { read: ElementRef, static: true })
  public inputSearchPet!: ElementRef;

  public servicesSelected: Array<IPetService> = [];

  public addonsSelected: Array<IPetServiceAddon[]> = [];
  constructor(public utilsService: UtilsService) {}

  ngOnInit() {
    this.addBlankServiceService();
    this.startDelayedSearchCustomers();
  }

  public changeCustomer(
    customerId: string | null,
    customer: ICustomer | null
  ): void {
    this.newAppointmentForm.controls.customer.setValue(customer);
    this.inputSearchPet.nativeElement.value = '';
    this.change.emit({
      change: Object.assign({}, this.newAppointmentForm.getRawValue(), {
        customer,
      }),
      errors: this.newAppointmentForm.invalid,
    });
    this.newAppointmentForm.clearValidators();
  }

  public changePet(petId: string, pet: IPet): void {
    this.newAppointmentForm.controls.pet.setValue(pet);
    this.change.emit({
      change: Object.assign({}, this.newAppointmentForm.getRawValue(), {
        pet,
      }),
      errors: this.newAppointmentForm.invalid,
    });
    this.newAppointmentForm.clearValidators();
  }

  public emitError(): void {
    this.change.emit({
      change: Object.assign({}, this.newAppointmentForm.getRawValue()),
      errors: true,
    });
  }

  public changeOperator(
    operatorId: string | null,
    operator: IOperator | null
  ): void {
    this.newAppointmentForm.controls.operator.setValue(operator);
    this.change.emit({
      change: Object.assign({}, this.newAppointmentForm.getRawValue(), {
        operator,
      }),
      errors:
        this.utilsService.isEmptyObject(operator) &&
        this.newAppointmentForm.invalid,
    });
    this.newAppointmentForm.clearValidators();
  }

  public changeTable(tableId: string, table: any): void {
    this.newAppointmentForm.controls.table.setValue(table);
    this.change.emit({
      change: Object.assign({}, this.newAppointmentForm.getRawValue(), {
        table,
      }),
      errors: this.newAppointmentForm.invalid,
    });
    this.newAppointmentForm.clearValidators();
  }

  public addService(serviceId: string, service: IPetService): void {
    this.servicesSelected[this.servicesSelected.length - 1] = service;
    this.addonsSelected.push(service.addons as IPetServiceAddon[]);
    this.change.emit({
      change: Object.assign({}, this.newAppointmentForm.getRawValue(), {
        services: this.servicesSelected,
      }),
      errors: this.newAppointmentForm.invalid,
    });
    this.newAppointmentForm.clearValidators();
  }

  public addBlankServiceService(): void {
    if (
      !this.utilsService.isEmptyObject(
        this.servicesSelected[this.servicesSelected.length - 1]
      )
    ) {
      this.servicesSelected.push({} as IPetService);
    }
  }

  public removeService(service: IPetService): void {
    const serviceToRemoveIndex = this.servicesSelected.findIndex(
      (s) => service.id === s.id
    );
    this.servicesSelected.splice(serviceToRemoveIndex, 1);
    this.addonsSelected.splice(serviceToRemoveIndex, 1);

    this.change.emit({
      change: Object.assign({}, this.newAppointmentForm.getRawValue(), {
        services: this.servicesSelected,
      }),
      errors: this.newAppointmentForm.invalid,
    });

    if (this.servicesSelected.length === 0) {
      this.addBlankServiceService();
    }
    this.newAppointmentForm.clearValidators();
  }

  private startDelayedSearchCustomers(): void {
    fromEvent(this.inputSearchCustomers.nativeElement, 'input')
      .pipe(debounceTime(500))
      .subscribe((e: any) =>
        this.onSearchCustomer.emit(
          this.inputSearchCustomers.nativeElement.value
        )
      );
  }
}
