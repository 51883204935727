import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IGroomingProfile } from './models/i-grooming-profile';
import { Observable } from 'rxjs';
import { IApiResponse } from './models/i-api-response';
import { map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: ApiService,
              private snackBar: MatSnackBar) {}

  public getProfile(): Observable<IGroomingProfile> {
    return this.apiService.get('').pipe(
      map((res: IApiResponse) => {
        return {
          groomingName: 'Allegra toelettatura',
          name: 'Mario',
          surname: 'Fucsia',
          email: 'marion@allegrapet.it',
          numberOfTables: 4,
          password: '',
          telephone: '063828399',
          mobilePhone: '3476273829',
        } as IGroomingProfile;
      })
    );
  }

  public saveProfile(profile: IGroomingProfile): Observable<IApiResponse> {
    return this.apiService.patch('', profile).pipe(
      tap((res) => {
        if (res) {
          this.snackBar.open('Profilo aggiornato', '', {
            duration: 2000,
            panelClass: ['success-api'],
          });
        }
      })
    );
  }

  public uploadImage(file: File): Observable<IApiResponse> {
    const formData: FormData = new FormData();
    formData.append('groomingImage', file, file.name);
    return this.apiService.post('', formData).pipe(
      tap((res) => {
        if (res) {
          this.snackBar.open('Immagine modificata correttamente', '', {
            duration: 2000,
            panelClass: ['success-api'],
          });
        }
      })
    );
  }

  public getImage(): Observable<string> {
    return this.apiService
      .get('')
      .pipe(map((res: IApiResponse) => '../../assets/placeholder.svg'));
  }
}
