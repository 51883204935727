import { AppState } from '../../../state/app.state';
import { IAppointment } from '../../../../../../api/src/lib/models/i-appointment';

export const getAppointments = (state: AppState) => {
  if (!state.appointments) {
    return [];
  }
  return state.appointments.appointments;
};

export const getSelectedAppointment = (state: AppState) => {
  if (state.appointments.newAppointment) {
    return state.appointments.newAppointment;
  }

  const selectedAppointment = state.appointments.appointments.find(
    (a: IAppointment) => a.id === state.appointments.selectedAppointmentId
  );
  if (selectedAppointment) {
    return selectedAppointment;
  } else {
    return undefined;
  }
};

export const getSelectedAppointmentId = (state: AppState) => {
  return state.appointments.selectedAppointmentId;
};

export const getAppointmentRegistrationTab = (state: AppState) => {
  return state.appointments.appointmentRegistrationTab;
};

export const getSelectedAppointmentServices = (state: AppState) => {
  if (state.appointments.newAppointment) {
    return state.appointments.newAppointment.services;
  }

  const selectedAppointment = state.appointments.appointments.find(
    (a: IAppointment) => a.id === state.appointments.selectedAppointmentId
  );
  if (selectedAppointment) {
    return selectedAppointment.services;
  } else {
    return [];
  }
};
