<mat-toolbar color="primary">
  <img [src]="'../../assets/logo.svg'"
       (click)="navigate('/')"
       class="logo">
  <span class="spacer"></span>
  <button mat-icon-button
          (click)="navigate('notifications')">
    <mat-icon [ngClass]="'topbar-icon ' + (getActivePage(selectedPage$ | async) === 'notifications' ? 'active' : '')">notifications</mat-icon>
  </button>
  <button mat-icon-button
          (click)="navigate('search')">
    <mat-icon [ngClass]="'topbar-icon ' + (getActivePage(selectedPage$ | async) === 'search' ? 'active' : '')">search</mat-icon>
  </button>
  <button mat-icon-button
          (click)="navigate('settings/profile')">
    <mat-icon [ngClass]="'topbar-icon ' + (getActivePage(selectedPage$ | async) === 'settings' ? 'active' : '')">settings</mat-icon>
  </button>
  <img [src]="'../../assets/placeholder.svg'"
       class="picture">
</mat-toolbar>

<mat-drawer-container class="sidenav-dynamic-container"
                      [style.display]="showSidenav ? 'block' : 'none'"
                      [style.width]="sidenavWidth">
  <mat-drawer #drawer class="sidenav-dynamic" mode="side">
    <ng-container
      [ngTemplateOutlet]="sidenavTemplate"
      [ngTemplateOutletContext]="{ $implicit: sidenavTemplateParams}">
    </ng-container>
  </mat-drawer>
</mat-drawer-container>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav mode="side"
               opened
               class="sidenav"
               [fixedInViewport]="false"
               [fixedTopGap]="0"
               [fixedBottomGap]="0">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button mat-icon-button>
          <button mat-icon-button (click)="navigate('calendar')">
            <mat-icon [ngClass]="'sidenav-icon ' + (getActivePage(selectedPage$ | async) === 'calendar' ? 'active' : '')">date_range</mat-icon>
          </button>
        </button>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <button mat-icon-button (click)="navigate('customers')">
          <mat-icon [ngClass]="'sidenav-icon ' + (getActivePage(selectedPage$ | async) === 'customers' ? 'active' : '')">person</mat-icon>
        </button>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <button mat-icon-button (click)="navigate('operators')">
          <mat-icon [ngClass]="'sidenav-icon ' + (getActivePage(selectedPage$ | async) === 'operators' ? 'active' : '')">people</mat-icon>
        </button>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <button mat-icon-button>
          <mat-icon class="sidenav-icon">signal_cellular_alt</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet (activate)="onActivate($event)">
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
