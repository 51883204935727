<span class="total" *ngIf="configs?.header?.showTotal">
  RISULTATI:
  <span class="total__value">{{datasource?.count$ | async}}</span>
</span>
<table mat-table
       matSort
       [dataSource]="datasource"
       [matSortDirection]="sortingDirection"
       [matSortStart]="sortingDirection"
       [matSortActive]="sortingActiveFields"
       #table
       (matSortChange)="onSortChange($event)"
       class="mat-elevation-z0 table">
    <ng-container *ngFor="let col of columns" matColumnDef="{{col.name}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disabled="{{!col.sorting}}"> {{col.name | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [style.borderLeft]="(col.borderLeftColorKey ? ('4px solid ' + element[col.borderLeftColorKey]) : '')"
          [ngClass]="(col.borderLeft ? 'border-left ' : ' ') + (element.isEnabled && col.type !== 'action' ? '' : (element.isEnabled == false ? 'disabled' : '')) + ' ' + (col.type == 'action' ? 'action-btn' : '')"
          [style.maxWidth]="col?.maxWidth"
          [style.textAlign]="col?.textAlign"
          [style.width]="col?.width"
          (click)="col.type !== 'action' ? cellClicked.emit(element) : null">
        <span *ngIf="col.type == 'string'"
              [style.width]="col?.width"
              [style.textAlign]="col?.textAlign"
              [style.maxWidth]="col?.maxWidth">
          {{col.translate ? (col.key + '.' + element[col.key] | translate) : element[col.key]}}
        </span>
        <span *ngIf="col.type == 'currency'"
              [style.textAlign]="col?.textAlign"
              [style.width]="col?.width"
              [style.maxWidth]="col?.maxWidth">
          {{element[col.key] | currency:(configs?.currency ? configs.currency : 'EUR')}}
        </span>
        <span *ngIf="col.type == 'time'"
              [style.textAlign]="col?.textAlign"
              [style.width]="col?.width"
              [style.maxWidth]="col?.maxWidth">
          {{element[col.key] | date:'HH:mm'}}
        </span>
        <mat-slide-toggle
          *ngIf="col.type =='action' && col.actionType == 'status'"
          class="status"
          [checked]="element.isEnabled"
          (change)="changeStatusClicked.emit({element: element, newStatus: $event.checked})">
        </mat-slide-toggle>
        <mat-icon  *ngIf="col.type =='action' && col.actionType == 'delete'"
                   class="delete"
                   (click)="removeClicked.emit(element)">delete</mat-icon>
      </td>
    </ng-container>
  <tr mat-header-row *matHeaderRowDef="visibleColumnsNames" [style.display]="configs?.header?.show ? '' : 'none'"></tr>
  <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
</table>
<span class="add-new-row" *ngIf="configs?.addNewRow" (click)="addRowClicked.emit()">
  <mat-icon>add_circle</mat-icon>
  <div class="add-new-row_text">{{configs.addText}}</div>
</span>
