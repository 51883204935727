<mat-form-field class="capitalize w-75 mr-3 {{label ? ' ' : ' select'}}"
                appearance="outline"
                floatLabel="always">
  <div class="divisor"></div>
  <mat-label>{{label}}</mat-label>
  <mat-select disableRipple
                [value]="(selectedValue$ | async) ? (selectedValue$ | async)?.value : (selectedValue ? selectedValue : (options && options[0] ? options[0].value : ''))">
      <mat-select-trigger>
        <ng-container
          *ngIf="template && !templateSelected"
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ $implicit: (selectedValue$ | async) ? (selectedValue$ | async) : (options && options[0].text ? options[0].text : '')}">
        </ng-container>
        <ng-container
          *ngIf="templateSelected"
          [ngTemplateOutlet]="templateSelected"
          [ngTemplateOutletContext]="{ $implicit: (selectedValue$ | async)}">
        </ng-container>
        <div *ngIf="!template">{{(selectedValue$ | async) ? (selectedValue$ | async)?.value : (selectedValue ? selectedValue : (options && options[0] ? options[0].text : ''))}}</div>
      </mat-select-trigger>
      <mat-option *ngFor="let opt of options"
                  (click)="selected.emit(opt)"
                  value="{{opt.value}}">
        <ng-container
          *ngIf="template"
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ $implicit: opt }">
        </ng-container>
        <div *ngIf="!template">{{opt.text}}</div>
        <mat-slide-toggle
          *ngIf="options && options[0]?.additionalProps && options[0]?.additionalProps['isEnabled']"
          class="isEnabled"
          [checked]="options[0]?.additionalPropsValue['isEnabled']"
          (change)="enabledChange.emit({id: options[0]?.additionalPropsValue['id'], newStatus: $event.checked})">
        </mat-slide-toggle>
      </mat-option>
  </mat-select>
</mat-form-field>
