import { Action } from '@ngrx/store';
import { IOperator } from '../../../../../../api/src/lib/models/i-operator';

export const GET_OPERATORS = '[Operators] Get operators';
export const GET_OPERATORS_SUCCESS = '[Operators] Get operators success';

export const SELECT_OPERATOR = '[Operators] Select operator';
export const SELECT_OPERATOR_SUCCESS = '[Operators] Select operator success';

export const UNSELECT_OPERATOR = '[Operators] Unselect operator';

export const CHANGE_OPERATOR_STATUS = '[Operators] Change operator status';
export const CHANGE_OPERATOR_STATUS_SUCCESS =
  '[Operators] Change operator status success';

export const SET_STATUS_OPERATOR_IS_DIRTY =
  '[Operators] Set status operator dirty';

export const NEW_OPERATOR = '[Operators] New Operator';

export const SAVE_OPERATOR = '[Operators] Save operator';
export const SAVE_OPERATOR_SUCCESS = '[Operators] Save operator success';

export const DELETE_OPERATOR = '[Operators] Delete operator';
export const DELETE_OPERATOR_SUCCESS = '[Operators] Delete operator success';
export const SET_OPERATOR_TAB = '[Operators] Set operator tab';
export const UPDATE_NEW_OPERATOR = '[Operators] Update new operator';

export const GET_AVAILABLES_COLORS = '[Operators] Get Availables Colors';
export const GET_AVAILABLES_COLORS_SUCCESS =
  '[Operators] Get Availables Colors Success';
export const SET_SORTING_OPERATORS = '[Operators] Set Sorting operators';

export class GetOperators implements Action {
  readonly type = GET_OPERATORS;

  constructor(
    public sort?: Array<string>,
    public order?: Array<'asc' | 'desc'>
  ) {}
}

export class GetOperatorsSuccess implements Action {
  readonly type = GET_OPERATORS_SUCCESS;

  constructor(public operators: IOperator[]) {}
}

export class SelectOperator implements Action {
  readonly type = SELECT_OPERATOR;

  constructor(public operatorId?: string, public navigate: boolean = true) {}
}

export class SelectOperatorSuccess implements Action {
  readonly type = SELECT_OPERATOR_SUCCESS;

  constructor(public operatorId: string) {}
}

export class UnselectOperator implements Action {
  readonly type = UNSELECT_OPERATOR;

  constructor(public tab: 'anagraphic' | 'week-scheduler') {}
}

export class SetSortingOperators implements Action {
  readonly type = SET_SORTING_OPERATORS;

  constructor(
    public sort: Array<string>,
    public order: Array<'asc' | 'desc'>
  ) {}
}

export class SetStatusOperatorIsDirty implements Action {
  readonly type = SET_STATUS_OPERATOR_IS_DIRTY;

  constructor(public status: boolean) {}
}

export class DeleteOperator implements Action {
  readonly type = DELETE_OPERATOR;

  constructor(public operator: IOperator) {}
}

export class DeleteOperatorSuccess implements Action {
  readonly type = DELETE_OPERATOR_SUCCESS;

  constructor(public operatorId: string) {}
}

export class ChangeOperatorStatus implements Action {
  readonly type = CHANGE_OPERATOR_STATUS;

  constructor(public operatorId: string) {}
}

export class ChangeOperatorStatusSuccess implements Action {
  readonly type = CHANGE_OPERATOR_STATUS_SUCCESS;

  constructor(public operatorId: string) {}
}

export class NewOperator implements Action {
  readonly type = NEW_OPERATOR;

  constructor(public operatorId: string) {}
}

export class SetOperatorTab implements Action {
  readonly type = SET_OPERATOR_TAB;

  constructor(public tab: 'anagraphic' | 'week-scheduler') {}
}

export class SaveOperator implements Action {
  readonly type = SAVE_OPERATOR;

  constructor(public operator: IOperator) {}
}

export class SaveOperatorSuccess implements Action {
  readonly type = SAVE_OPERATOR_SUCCESS;

  constructor(public operator: IOperator, public newOperator: boolean) {}
}

export class UpdateNewOperator implements Action {
  readonly type = UPDATE_NEW_OPERATOR;

  constructor(public operator: IOperator | undefined, public errors: boolean) {}
}

export class GetAvailablesColors implements Action {
  readonly type = GET_AVAILABLES_COLORS;

  constructor() {}
}

export class GetAvailablesColorsSuccess implements Action {
  readonly type = GET_AVAILABLES_COLORS_SUCCESS;

  constructor(public colors: { color: string; name: string }[]) {}
}

export type Actions =
  | GetOperators
  | GetOperatorsSuccess
  | SelectOperator
  | SelectOperatorSuccess
  | DeleteOperator
  | DeleteOperatorSuccess
  | ChangeOperatorStatus
  | ChangeOperatorStatusSuccess
  | UnselectOperator
  | NewOperator
  | SetOperatorTab
  | SaveOperator
  | SaveOperatorSuccess
  | GetAvailablesColors
  | SetSortingOperators
  | UpdateNewOperator
  | SetStatusOperatorIsDirty
  | GetAvailablesColorsSuccess;
