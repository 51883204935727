import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../state/app.state';
import { SetPage } from '../state/home.action';
import { getPage } from '../state/home.selector';

@Component({
  selector: 'romeo-grooming-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit {
  @Input() selectedPage$: Observable<string>;

  constructor(public store: Store<AppState>) {
    this.selectedPage$ = this.store.select(getPage);
  }

  public activateTab(name: string): void {
    this.store.dispatch(new SetPage('settings/' + name));
  }

  ngOnInit(): void {}
}
