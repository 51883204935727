import { NgModule } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UserService } from './user.service';
import { CustomersService } from './customers.service';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, MatSnackBarModule],
  providers: [ApiService, UserService, CustomersService],
  exports: [],
})
export class ApiModule {}
