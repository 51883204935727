import { Action } from '@ngrx/store';

export const SET_PAGE = '[Home] Set page';
export const SET_PAGE_SUCCESS = '[Home] Set page success';

export class SetPage implements Action {
  readonly type = SET_PAGE;

  constructor(public payload: string) {}
}

export class SetPageSuccess implements Action {
  readonly type = SET_PAGE_SUCCESS;

  constructor(public payload: string) {}
}

export type Actions = SetPage | SetPageSuccess;
