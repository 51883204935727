<mat-icon class="modal-close" [mat-dialog-close]="null">close</mat-icon>
<h1 class="modal-title text-center" mat-dialog-title>Attenzione</h1>
<div class="modal-content" mat-dialog-content>
  <p>Sei sicuro di voler uscire? <br/>Se esci perderai le modifiche effettuate</p>
  <p class="fw-bold" *ngIf="data?.warningText"> {{ data?.warningText }}<br/>
    <span *ngIf="data?.cantSave">Non puoi salvare perchè i campi obbligatori non sono stati tutti compilati</span>
  </p>
</div>
<div mat-dialog-actions class="modal-buttons flex-column">
  <button *ngIf="!data?.cantSave" class="btn-modal mat-primary mb-1 px-4 primary" color="primary" mat-raised-button [mat-dialog-close]="true">Salva ed esci</button>
  <button *ngIf="data?.cantSave" class="btn-modal mat-primary mb-1 px-4 primary" [mat-dialog-close]="null">Continua a compilare il form</button>
  <button *ngIf="!data?.hideCancelButton" class="btn-modal is-inverse mb-3 px-4" mat-raised-button [mat-dialog-close]="false">Esci senza salvare</button>
</div>
