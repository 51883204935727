import { Action } from '@ngrx/store';
import { ICustomer } from '../../../../../../api/src/lib/models/i-customer';
import { IPet } from '../../../../../../api/src/lib/models/i-pet';
import { PetType } from '../../../../../../api/src/lib/models/pet-type';

export const GET_CUSTOMERS = '[Customers] Get customers';
export const GET_CUSTOMERS_SUCCESS = '[Customers] Get customers success';

export const GET_DOGS = '[Pets] Get dogs';
export const GET_DOGS_SUCCESS = '[Pets] Get dogs success';

export const GET_CATS = '[Pets] Get cats';
export const GET_CATS_SUCCESS = '[Pets] Get cats success';

export const GET_SELECTED_CUSTOMER = '[Customers] Get selected customer';
export const GET_SELECTED_CUSTOMER_SUCCESS =
  '[Customers] Get selected customer success';

export const CHANGE_CUSTOMER_FILTER = '[Customers] Change customers filter';
export const SELECT_CUSTOMER = '[Customers] Select customer';
export const SELECT_CUSTOMER_SUCCESS = '[Customers] Select customer success';
export const NEW_CUSTOMER = '[Customers] New customer';
export const SET_STATUS_CUSTOMER_DIRTY =
  '[Customers] Set status customer dirty';
export const SAVE_CUSTOMER = '[Customers] Save customer';
export const SAVE_CUSTOMER_SUCCESS = '[Customers] Save customer success';
export const REMOVE_CUSTOMER = '[Customers] Remove customer';
export const REMOVE_CUSTOMER_SUCCESS = '[Customers] Remove customer success';

export const SELECT_PET = '[Pets] Select pet';
export const SELECT_PET_SUCCESS = '[Pets] Select pet success';
export const CREATE_PET = '[Pets] Create pet';
export const CREATE_PET_SUCCESS = '[Pets] Create pet success';

export const UPDATE_SELECTED_PET = '[Pets] Update pet';

export const REMOVE_PET = '[Pets] Remove pet';
export const REMOVE_PET_SUCCESS = '[Pets] Remove pet success';

export const UPDATE_NEW_CUSTOMER = '[Customers] Update new customer';
export const UPDATE_NEW_PET =
  '[Customers] Update new pet associated to new customer';
export const ADD_NEW_PET_TO_SELECTED_CUSTOMER =
  '[Customers] Add new pet to selected customer';
export const ADD_NEW_PET_TO_SELECTED_CUSTOMER_SUCCESS =
  '[Customers] Add new pet to selected customer success';

export const SET_CUSTOMER_TAB = '[Customers] Set customer tab';
export const SET_SORTING_OWNER = '[Customers] Set sorting owner';
export const SET_SORTING_CAT = '[Customers] Set sorting cat';
export const SET_SORTING_DOG = '[Customers] Set sorting dog';

export const UNSELECT_CUSTOMER = '[Customers] Unselect customer';

export class GetCustomers implements Action {
  readonly type = GET_CUSTOMERS;

  constructor(
    public offset?: number,
    public limit?: number,
    public sort?: Array<string>,
    public order?: Array<'asc' | 'desc'>,
    public search?: string
  ) {}
}

export class GetDogs implements Action {
  readonly type = GET_DOGS;

  constructor(
    public offset?: number,
    public limit?: number,
    public sort?: Array<string>,
    public order?: Array<'asc' | 'desc'>,
    public search?: string
  ) {}
}

export class GetDogsSuccess implements Action {
  readonly type = GET_DOGS_SUCCESS;

  constructor(
    public dogs: IPet[],
    public offset: number,
    public limit: number,
    public operation: string
  ) {}
}

export class GetCats implements Action {
  readonly type = GET_CATS;

  constructor(
    public offset?: number,
    public limit?: number,
    public sort?: Array<string>,
    public order?: Array<'asc' | 'desc'>,
    public search?: string
  ) {}
}

export class GetCatsSuccess implements Action {
  readonly type = GET_CATS_SUCCESS;

  constructor(
    public cats: IPet[],
    public offset: number,
    public limit: number,
    public operation: string
  ) {}
}

export class GetCustomersSuccess implements Action {
  readonly type = GET_CUSTOMERS_SUCCESS;

  constructor(
    public customers: ICustomer[],
    public offset: number,
    public limit: number,
    public operation: string
  ) {}
}

export class GetSelectedCustomer implements Action {
  readonly type = GET_SELECTED_CUSTOMER;

  constructor(public id: string) {}
}

export class GetSelectedCustomerSuccess implements Action {
  readonly type = GET_SELECTED_CUSTOMER_SUCCESS;

  constructor(public customer: ICustomer) {}
}

export class ChangeCustomersFilter implements Action {
  readonly type = CHANGE_CUSTOMER_FILTER;

  constructor(public filter: string) {}
}

export class SetSortingOwner implements Action {
  readonly type = SET_SORTING_OWNER;

  constructor(
    public sort: Array<string>,
    public order: Array<'asc' | 'desc'>
  ) {}
}

export class SetSortingDog implements Action {
  readonly type = SET_SORTING_DOG;

  constructor(
    public sort: Array<string>,
    public order: Array<'asc' | 'desc'>
  ) {}
}

export class SetSortingCat implements Action {
  readonly type = SET_SORTING_CAT;

  constructor(
    public sort: Array<string>,
    public order: Array<'asc' | 'desc'>
  ) {}
}

export class SelectCustomer implements Action {
  readonly type = SELECT_CUSTOMER;

  constructor(public customerId?: string) {}
}

export class RemoveCustomer implements Action {
  readonly type = REMOVE_CUSTOMER;

  constructor(public customer: ICustomer) {}
}

export class RemoveCustomerSuccess implements Action {
  readonly type = REMOVE_CUSTOMER_SUCCESS;

  constructor(public removedCustomerId: string) {}
}

export class SelectCustomerSuccess implements Action {
  readonly type = SELECT_CUSTOMER_SUCCESS;

  constructor(public customerId: string) {}
}

export class NewCustomer implements Action {
  readonly type = NEW_CUSTOMER;

  constructor(public customerId: string) {}
}

export class SetStatusCustomerDirty implements Action {
  readonly type = SET_STATUS_CUSTOMER_DIRTY;

  constructor(public status: boolean) {}
}

export class UpdateNewCustomer implements Action {
  readonly type = UPDATE_NEW_CUSTOMER;

  constructor(public customer: ICustomer | undefined, public errors: boolean) {}
}

export class UpdateNewPet implements Action {
  readonly type = UPDATE_NEW_PET;

  constructor(public pet: IPet | undefined, public errors: boolean) {}
}

export class AddNewPetToSelectedCustomer implements Action {
  readonly type = ADD_NEW_PET_TO_SELECTED_CUSTOMER;

  constructor(
    public pet: IPet | undefined,
    public customerId: string,
    public customerListFilter: string
  ) {}
}

export class AddNewPetToSelectedCustomerSuccess implements Action {
  readonly type = ADD_NEW_PET_TO_SELECTED_CUSTOMER_SUCCESS;

  constructor(public customer: ICustomer, public pet: IPet) {}
}

export class UpdateSelectedPet implements Action {
  readonly type = UPDATE_SELECTED_PET;

  constructor(public pet: IPet | undefined, public errors: boolean) {}
}

export class SaveCustomer implements Action {
  readonly type = SAVE_CUSTOMER;

  constructor(public customer: ICustomer) {}
}

export class SaveCustomerSuccess implements Action {
  readonly type = SAVE_CUSTOMER_SUCCESS;

  constructor(public customer: ICustomer, public newCustomer: boolean) {}
}

export class SelectPet implements Action {
  readonly type = SELECT_PET;

  constructor(public ownerId: string, public petId: string) {}
}

export class SelectPetSuccess implements Action {
  readonly type = SELECT_PET_SUCCESS;

  constructor(public ownerId: string, public pet: IPet, public petId: string) {}
}

export class SetCustomerTab implements Action {
  readonly type = SET_CUSTOMER_TAB;

  constructor(public tab: 'owner' | 'pets') {}
}

export class CreatePet implements Action {
  readonly type = CREATE_PET;

  constructor(public ownerId: string) {}
}

export class CreatePetSuccess implements Action {
  readonly type = CREATE_PET_SUCCESS;

  constructor() {}
}

export class RemovePet implements Action {
  readonly type = REMOVE_PET;

  constructor(
    public ownerId: string,
    public petId: string,
    public petType: PetType | string
  ) {}
}

export class RemovePetSuccess implements Action {
  readonly type = REMOVE_PET_SUCCESS;

  constructor(
    public pets: IPet[],
    public removedPetType: PetType,
    public removedPetId: string,
    public ownerId: string
  ) {}
}

export class UnselectCustomer implements Action {
  readonly type = UNSELECT_CUSTOMER;

  constructor() {}
}

export type Actions =
  | GetCustomers
  | GetCustomersSuccess
  | ChangeCustomersFilter
  | SelectCustomer
  | SelectCustomerSuccess
  | NewCustomer
  | SetStatusCustomerDirty
  | SaveCustomer
  | SaveCustomerSuccess
  | SelectPet
  | GetDogs
  | GetDogsSuccess
  | GetCats
  | GetCatsSuccess
  | SelectPetSuccess
  | SetCustomerTab
  | CreatePet
  | CreatePetSuccess
  | RemovePet
  | RemovePetSuccess
  | UpdateNewCustomer
  | UpdateSelectedPet
  | UpdateNewPet
  | AddNewPetToSelectedCustomer
  | AddNewPetToSelectedCustomerSuccess
  | RemoveCustomer
  | RemoveCustomerSuccess
  | SetSortingOwner
  | SetSortingCat
  | SetSortingDog
  | GetSelectedCustomer
  | GetSelectedCustomerSuccess
  | UnselectCustomer;
