import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';

@Component({
  selector: 'lib-sticky-save',
  templateUrl: './sticky-save.component.html',
  styleUrls: ['./sticky-save.component.scss']
})
export class StickySaveComponent implements OnInit {

  @Input() model: any;
  @Input() show: boolean | null = false;
  @Input() template!: TemplateRef<any>;
  @Input() templateOpts: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
