import { NgModule } from '@angular/core';
import { InputNumberComponent } from './input-number/input-number.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SelectComponent } from './select/select.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslationModule } from '../../../translation/src/lib/translation.module';
import { AreyousureComponent } from './areyousure/areyousure.component';
import { MatDialogModule } from '@angular/material/dialog';
import { StickySaveComponent } from './sticky-save/sticky-save.component';
import { MatSortModule } from '@angular/material/sort';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarModule } from './calendar/calendar.module';

@NgModule({
  declarations: [
    InputNumberComponent,
    ImageViewerComponent,
    TableComponent,
    SelectComponent,
    AreyousureComponent,
    StickySaveComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatTableModule,
    MatSlideToggleModule,
    MatOptionModule,
    MatSelectModule,
    TranslationModule,
    MatDialogModule,
    MatSortModule,
    CalendarModule,
  ],
  exports: [
    InputNumberComponent,
    ImageViewerComponent,
    TableComponent,
    SelectComponent,
    AreyousureComponent,
    StickySaveComponent,
    CalendarComponent,
  ],
})
export class UiModule {}
