import { AppState } from '../../../state/app.state';
import { ISelectOption } from '../../../../../../ui/src/lib/select/i-select-option';

export const getOperators = (state: AppState) => {
  if (state.operators) {
    return state.operators.operators;
  } else {
    return [];
  }
};

export const getOperatorsSelectOptions = (state: AppState) => {
  const selectOptions: ISelectOption[] = [];

  for (const operator of state.operators.operators) {
    selectOptions.push({
      text: operator.fullName,
      value: operator,
      id: operator.id,
    } as ISelectOption);
  }
  return selectOptions;
};

export const getSelectedOperator = (state: AppState) => {
  if (state.operators.newOperator) {
    return state.operators.newOperator;
  }

  const selectedOperator = state.operators.operators.find(
    (s) => s.id === state.operators.selectedOperatorId
  );
  if (selectedOperator) {
    return selectedOperator;
  } else {
    return undefined;
  }
};

export const getFormOperatorErrors = (state: AppState) => {
  return state.operators.formOperatorErrors;
};

export const getSelectedOperatorId = (state: AppState) => {
  return state.operators.selectedOperatorId;
};

export const getSelectedOperatorStatusIsDirty = (state: AppState) => {
  return state.operators.statusIsDirty;
};

export const getOperatorTab = (state: AppState) => {
  return state.operators.operatorTab;
};

export const getSortingOperators = (state: AppState) => {
  return state.operators.sortingOperators;
};

export const getAvailablesColorsSelectOptions = (state: AppState) => {
  const selectOptions: ISelectOption[] = [];

  for (const color of state.operators.availablesColors) {
    selectOptions.push({
      text: color.name,
      value: color.color,
      id: color.color,
    } as ISelectOption);
  }
  return selectOptions;
};

export const getSelectedOperatorColorOption = (state: AppState) => {
  const selectedOperator = state.operators.newOperator
    ? state.operators.newOperator
    : state.operators.operators.find(
        (s) => s.id === state.operators.selectedOperatorId
      );

  const color = selectedOperator?.color;

  const colorName = state.operators.availablesColors.find(
    (c) => color === c.color
  );

  if (selectedOperator && colorName) {
    return {
      text: colorName.name,
      value: colorName.color,
      id: colorName.color,
    };
  } else {
    return {
      text: state.operators.availablesColors[0].name,
      value: state.operators.availablesColors[0].color,
      id: state.operators.availablesColors[0].color,
    } as ISelectOption;
  }
};
