<form [formGroup]="profileForm"
      (ngSubmit)="onSubmit()">
  <mat-grid-list [cols]="8" class="main-grid-list">
    <mat-grid-tile
      [colspan]="2"
      [rowspan]="1">
    <lib-image-viewer
      class="picture"
      [placeholder]="'../../assets/placeholder.svg'"
      [image]="groomingImage"
      (loaded)="saveImage($event)">
    </lib-image-viewer>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="6"
      [rowspan]="4">
      <span class="input-fields">
        <mat-grid-list [cols]="8">
          <mat-grid-tile
            [colspan]="4">
            <mat-form-field
              floatLabel="always"
              appearance="outline">
              <mat-label>Nome della toelettatura</mat-label>
              <input matInput
                     formControlName="groomingName"
                     placeholder="Nome della toelettatura">
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile
               [colspan]="4">
              <lib-input-number
                    formControlName="numberOfTables"
                    [placeholder]="'Numero di tavoli'"
                    [value]="0"
                    [min]="0"
                    [label]="'Numero di tavoli'"
                    [max]="7">
              </lib-input-number>
          </mat-grid-tile>

          <mat-grid-tile
            [colspan]="4">
            <mat-form-field
              floatLabel="always"
              appearance="outline">
              <mat-label>Nome</mat-label>
              <input matInput
                     [formControlName]="'name'"
                     placeholder="Nome">
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile
            [colspan]="4">
            <mat-form-field
              floatLabel="always"
              appearance="outline">
              <mat-label>Cognome</mat-label>
              <input matInput
                     [formControlName]="'surname'"
                     placeholder="Cognome">
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile
            [colspan]="4">
            <mat-form-field
              floatLabel="always"
              appearance="outline">
              <mat-label>Telefono</mat-label>
              <input matInput
                     [formControlName]="'telephone'"
                     type="tel"
                     placeholder="Telefono">
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile
            [colspan]="4">
            <mat-form-field
              floatLabel="always"
              appearance="outline">
              <mat-label>Cellulare</mat-label>
              <input matInput
                     type="tel"
                     [formControlName]="'mobilePhone'"
                     placeholder="Cellulare">
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile
            [colspan]="4">
            <mat-form-field
              floatLabel="always"
              appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput
                     [formControlName]="'email'"
                     type="email"
                     placeholder="Email">
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile
            [colspan]="4">
            <mat-form-field
              floatLabel="always"
              appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput
                     [formControlName]="'password'"
                     type="password"
                     placeholder="Password">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile
            [colspan]="8"
            [rowspan]="1">
              <button mat-raised-button color="primary" class="save-btn">Salva</button>
          </mat-grid-tile>
        </mat-grid-list>
      </span>
    </mat-grid-tile>
  </mat-grid-list>
</form>
