import { AppState } from '../../../../state/app.state';
import { ISelectOption } from '../../../../../../../ui/src/lib/select/i-select-option';

export const getServices = (state: AppState) => state.services.services;
export const servicesIsEmpty = (state: AppState) =>
  state.services.services.length === 0;
export const getSelectedPetType = (state: AppState) =>
  state.services.selectedPetType;

export const getSelectedAddonTier = (state: AppState) => {
  const selectedService = state.services.services.find(
    (s) => s.id === state.services.selectedServiceId
  );
  if (selectedService && selectedService.addons) {
    const selectedAddon = selectedService.addons.find(
      (a) => a.id === state.services.selectedAddonId
    );
    if (selectedAddon) {
      const selectedAddonTier = selectedAddon.tiers.find(
        (t) => t.id === state.services.selectedAddonTierId
      );
      return selectedAddonTier;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getSelectedService = (state: AppState) => {
  const selectedService = state.services.services.find(
    (s) => s.id === state.services.selectedServiceId
  );
  if (selectedService) {
    return selectedService;
  } else {
    return undefined;
  }
};
export const getSelectedAddon = (state: AppState) => {
  const selectedService = state.services.services.find(
    (s) => s.id === state.services.selectedServiceId
  );
  if (selectedService && selectedService.addons) {
    const selectedAddon = selectedService.addons.find(
      (a) => a.id === state.services.selectedAddonId
    );
    return selectedAddon;
  } else {
    return null;
  }
};

export const getSelectedAddonId = (state: AppState) => {
  return state.services.selectedAddonId;
};

export const getSelectedServiceAddonSelectOptions = (
  state: AppState,
  props: { category: string }
) => {
  const selectOptionArray = [];
  const selectedService = state.services.services.find(
    (s) => s.id === state.services.selectedServiceId
  );
  if (selectedService) {
    const addons = selectedService.addons;
    if (addons) {
      for (const addon of addons) {
        if (addon.category === props.category) {
          selectOptionArray.push({
            text: addon.name,
            value: addon.name,
            id: addon.id,
          } as ISelectOption);
        }
      }
    }
  }
  return selectOptionArray;
};

export const getSelectedServiceAddonByCategory = (
  state: AppState,
  props?: { category?: string }
) => {
  const selectedService = state.services.services.find(
    (s) => s.id === state.services.selectedServiceId
  );
  if (selectedService && selectedService.addons) {
    const addon = selectedService.addons.find((a) => {
      return (
        a.category ===
        (props ? props.category : state.services.selectedCategoryTab)
      );
    });
    return addon;
  }
  return null;
};

export const getSelectedServiceAddon = (state: AppState) => {
  const selectedService = state.services.services.find(
    (s) => s.id === state.services.selectedServiceId
  );
  if (selectedService && selectedService.addons) {
    const addon = selectedService.addons.find(
      (a) => a.id === state.services.selectedAddonId
    );
    if (addon) {
      return addon;
    } else {
      const selected = selectedService.addons.find(
        (a) => a.category === state.services.selectedCategoryTab
      );
      if (selected) {
        return selected;
      } else {
        return undefined;
      }
    }
  } else {
    return undefined;
  }
};

export const getSelectedServiceTiers = (state: AppState) => {
  const selectedService = state.services.services.find(
    (s) => s.id === state.services.selectedServiceId
  );
  if (selectedService) {
    return selectedService.tiers;
  } else {
    return [];
  }
};
export const getSelectedAddonTiers = (state: AppState) => {
  const selectedService = state.services.services.find(
    (s) => s.id === state.services.selectedServiceId
  );
  if (selectedService && selectedService.addons) {
    const selectedAddon = selectedService.addons.find(
      (a) => a.id === state.services.selectedAddonId
    );
    if (selectedAddon) {
      return selectedAddon.tiers;
    } else {
      const addon = selectedService.addons.find(
        (a) => a.category === state.services.selectedCategoryTab
      );
      if (addon) {
        return addon.tiers;
      } else {
        return [];
      }
    }
  } else {
    return [];
  }
};

export const getServiceAddons = (
  state: AppState,
  props: { category?: string }
) => {
  const selectedService = state.services.services.find(
    (s) => s.id === state.services.selectedServiceId
  );
  if (props.category) {
    if (selectedService) {
      return selectedService.addons?.filter(
        (a) => a.category === props.category
      );
    } else {
      return [];
    }
  }
  if (selectedService) {
    return selectedService.addons;
  } else {
    return [];
  }
};

export const getSelectedServiceStatusIsDirty = (state: AppState) => {
  return state.services.statusIsDirty;
};
