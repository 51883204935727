import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApiService } from '../../../api/src/lib/api.service';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'romeo-grooming-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  title = 'grooming';

  constructor(
    public apiService: ApiService,
    public translate: TranslateService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {
    this.apiService.apiEndpoint = environment.apiEndpoint;
    translate.setDefaultLang('it');
    this.registerCustomIcons();
  }

  public registerCustomIcons() {
    this.matIconRegistry.addSvgIconInNamespace(
      'romeo',
      'cat',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/cat.svg'
      )
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'romeo',
      'dog',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/dog.svg'
      )
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'romeo',
      'wallet',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/wallet.svg'
      )
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'romeo',
      'clock',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/clock.svg'
      )
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'romeo',
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/trash.svg'
      )
    );
  }
}
