import { Injectable } from '@angular/core';
import { ICalendarWeek } from './models/i-calendar-week';
import { isFirstDayOfMonth, isLastDayOfMonth, startOfWeek } from 'date-fns';
import { ICalendarTimeslot } from './models/i-calendar-timeslot';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor() {}

  public fillWeekDaysArray(startDate: Date, dayIncrement: -1 | 1): Array<Date> {
    const weekdays = 7;
    const array = [];
    if (dayIncrement === -1) {
      startDate.setDate(
        startDate.getDate() +
          (startDate.getDay() ? weekdays - startDate.getDay() + 1 : 1)
      );
    } else {
      startDate.setDate(startDate.getDate() - 1);
    }
    for (const i of [1, 2, 3, 4, 5, 6, 7]) {
      startDate.setDate(startDate.getDate() + dayIncrement);
      array.push(new Date(startDate));
    }
    if (dayIncrement === -1) {
      array.reverse();
    }
    return array;
  }

  public isEqual(dateA: Date, dateB: Date): boolean {
    if (dateA instanceof Date) {
      return (
        dateA.getDate() === dateB.getDate() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getFullYear() === dateB.getFullYear()
      );
    } else {
      return false;
    }
  }

  public getWeeksInMonth(
    date: Date,
    firstDayOfWeekIndex = 1
  ): Array<ICalendarWeek> {
    const weekdays = 7;
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDayOfNextMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1
    );
    let days = this.fillWeekDaysArray(new Date(firstDayOfMonth), -1);
    const weeks = [];
    while (firstDayOfNextMonth.getTime() !== firstDayOfMonth.getTime()) {
      days[
        firstDayOfMonth.getDay() === 0
          ? (weekdays - firstDayOfWeekIndex) % weekdays
          : firstDayOfMonth.getDay() - firstDayOfWeekIndex
      ] = new Date(firstDayOfMonth);
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
      if (
        firstDayOfMonth.getDay() === firstDayOfWeekIndex ||
        firstDayOfNextMonth.getTime() === firstDayOfMonth.getTime()
      ) {
        weeks.push({ days });
        days = this.fillWeekDaysArray(new Date(firstDayOfMonth), 1);
      }
    }
    return weeks;
  }

  public isLastDateOfPreviousMonthOrFirstDateOfNextMonth(
    date: Date,
    currentMonth: number
  ): boolean {
    return (
      date.getMonth() !== currentMonth &&
      (isLastDayOfMonth(date) || isFirstDayOfMonth(date))
    );
  }

  public getWeek(date: Date): ICalendarWeek | undefined {
    const firstDay = startOfWeek(date, { weekStartsOn: 1 });
    const days = this.fillWeekDaysArray(firstDay, 1);
    return {
      days,
    };
  }

  public getTimeslots(
    timeslot: number,
    startTime: number,
    endTime: number
  ): Array<ICalendarTimeslot> {
    const slotsNumber = (endTime - startTime) / timeslot;
    const slots = [];
    for (let i = 0; i < slotsNumber; i++) {
      const startTimeData = new Date(0);
      startTimeData.setMinutes(startTime + i * 60);
      const endTimeData = new Date(0);
      endTimeData.setMinutes(endTime + (i + timeslot) * 60);

      slots.push({
        startTime: startTimeData,
        endTime: endTimeData,
      });
    }
    return slots;
  }
}
