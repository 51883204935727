import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { IGroomingProfile } from './models/i-grooming-profile';

@Injectable({ providedIn: 'root' })
export class UserResolver implements Resolve<IGroomingProfile[]> {
  constructor(private userService: UserService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.userService.getProfile();
  }
}
