import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ISelectOption } from './i-select-option';
import { Observable } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const SELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true,
};

@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SELECT_VALUE_ACCESSOR],
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @Input() options: Array<ISelectOption> | null = [];
  @Input() selectedValue$!: Observable<ISelectOption>;
  @Input() selectedValue!: ISelectOption;
  @Input() label!: string;
  @Input() template!: TemplateRef<any>;
  @Input() templateSelected!: TemplateRef<any>;

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  @Output() enabledChange: EventEmitter<any> = new EventEmitter<any>();

  private value!: ISelectOption;

  onChange = (value: ISelectOption) => {};
  onTouched = () => {};

  constructor() {}

  ngOnInit(): void {}

  writeValue(value: ISelectOption): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}
