import { Action } from '@ngrx/store';
import { IAppointment } from '../../../../../../api/src/lib/models/i-appointment';
import { IPetServiceTier } from '../../../../../../api/src/lib/models/i-pet-service-tier';
import { IPetServiceAddonTier } from '../../../../../../api/src/lib/models/i-pet-service-addon-tier';

export const GET_APPOINTMENTS = '[Appointments] Get appointments';
export const GET_APPOINTMENTS_SUCCESS =
  '[Appointments] Get appointments success';

export const SELECT_APPOINTMENT = '[Appointments] Select appointment';
export const SELECT_APPOINTMENT_SUCCESS =
  '[Appointments] Select appointment success';

export const UNSELECT_APPOINTMENT = '[Appointments] Unselect appointment';

export const SET_APPOINTMENT_REGISTRATION_TAB =
  '[Appointments] Set appointment registration tab';
export const UPDATE_NEW_APPOINTMENT_REGISTRATION =
  '[Appointments] Update new appointment registration';

export const REGISTER_APPOINTMENT = '[Appointments] Register appointment';
export const REGISTER_APPOINTMENT_SUCCESS =
  '[Appointments] Register appointment success';

export const SELECTED_APPOINTMENT_REMOVE_SERVICE =
  '[Appointments] Selected appointment remove service';
export const SELECTED_APPOINTMENT_ADD_SERVICES =
  '[Appointments] Selected appointment add service';

export const CREATE_APPOINTMENT = '[Appointments] Create appointment';

export class GetAppointments implements Action {
  readonly type = GET_APPOINTMENTS;

  constructor(
    public offset?: number,
    public limit?: number,
    public sort?: Array<string>,
    public order?: Array<'asc' | 'desc'>,
    public search?: string,
    public filters?: any
  ) {}
}

export class GetAppointmentsSuccess implements Action {
  readonly type = GET_APPOINTMENTS_SUCCESS;

  constructor(
    public appointments: IAppointment[],
    public offset: number,
    public limit: number,
    public operation: string
  ) {}
}

export class SelectAppointment implements Action {
  readonly type = SELECT_APPOINTMENT;

  constructor(public id: string) {}
}

export class SelectAppointmentSuccess implements Action {
  readonly type = SELECT_APPOINTMENT_SUCCESS;

  constructor(public appointment: IAppointment) {}
}

export class UnselectAppointment implements Action {
  readonly type = UNSELECT_APPOINTMENT;

  constructor() {}
}

export class SelectedAppointmentRemoveService implements Action {
  readonly type = SELECTED_APPOINTMENT_REMOVE_SERVICE;

  constructor(public id: string) {}
}

export class SelectedAppointmentAddServices implements Action {
  readonly type = SELECTED_APPOINTMENT_ADD_SERVICES;

  constructor(public services: Array<IPetServiceTier | IPetServiceAddonTier>) {}
}

export class CreateAppointment implements Action {
  readonly type = CREATE_APPOINTMENT;

  constructor(public appointment: IAppointment) {}
}

export class RegisterAppointment implements Action {
  readonly type = REGISTER_APPOINTMENT;

  constructor(public appointment: IAppointment) {}
}

export class RegisterAppointmentSuccess implements Action {
  readonly type = REGISTER_APPOINTMENT_SUCCESS;

  constructor() {}
}

export class SetAppointmentRegistrationTab implements Action {
  readonly type = SET_APPOINTMENT_REGISTRATION_TAB;

  constructor(public tab: 'appointment' | 'pet' | 'owner') {}
}

export class UpdateNewAppointmentRegistration implements Action {
  readonly type = UPDATE_NEW_APPOINTMENT_REGISTRATION;

  constructor(
    public appointment: IAppointment | any | undefined,
    public errors: boolean
  ) {}
}

export type Actions =
  | GetAppointments
  | GetAppointmentsSuccess
  | SelectAppointment
  | SelectAppointmentSuccess
  | UnselectAppointment
  | RegisterAppointment
  | RegisterAppointmentSuccess
  | UpdateNewAppointmentRegistration
  | SetAppointmentRegistrationTab
  | SelectedAppointmentRemoveService
  | SelectedAppointmentAddServices
  | CreateAppointment;
