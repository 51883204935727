import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CalendarViewType } from './models/calendar-view-type';
import { ICalendarEvent } from './models/i-calendar-event';
import { ICalendarEventConfigs } from './models/i-calendar-event-configs';
import { CalendarService } from './calendar.service';
import { TranslateService } from '@ngx-translate/core';
import { ICalendarTimeslotConfigs } from './models/i-calendar-timeslot-configs';

@Component({
  selector: 'lib-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit {
  @Input() events: Array<ICalendarEvent> = [];
  @Input() eventsConfigs!: ICalendarEventConfigs;
  @Input() timeSlotConfigs!: ICalendarTimeslotConfigs;
  @Input() viewType: CalendarViewType | string = CalendarViewType.monthly;
  @Input() selectedDate: Date = new Date();
  @Input() daysOff: Array<number> = [6];

  @Output() viewTypeChanged: EventEmitter<CalendarViewType> =
    new EventEmitter<CalendarViewType>();
  @Output() eventCreated: EventEmitter<ICalendarEvent> =
    new EventEmitter<ICalendarEvent>();
  @Output() dateChanged: EventEmitter<{ oldDate: Date; newDate: Date }> =
    new EventEmitter<{ oldDate: Date; newDate: Date }>();

  public weekDays: string[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  constructor(
    public calendarService: CalendarService,
    public cdr: ChangeDetectorRef,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  nextMonth(): void {
    this.selectedDate.setMonth(this.selectedDate.getMonth() + 1);
    this.selectedDate = new Date(this.selectedDate);
    this.cdr.detectChanges();
  }

  prevMonth(): void {
    this.selectedDate.setMonth(this.selectedDate.getMonth() - 1);
    this.selectedDate = new Date(this.selectedDate);
    this.cdr.detectChanges();
  }

  nextWeek(): void {
    this.selectedDate.setDate(this.selectedDate.getDate() + 7);
    this.selectedDate = new Date(this.selectedDate);
    this.cdr.detectChanges();
  }

  prevWeek(): void {
    this.selectedDate.setDate(this.selectedDate.getDate() - 7);
    this.selectedDate = new Date(this.selectedDate);
    this.cdr.detectChanges();
  }

  gotoWeek(date: Date): void {
    const week = this.calendarService.getWeek(date);

    if (week && week.days && week.days.length) {
      this.selectedDate.setDate(week.days[0].getDate());
      this.cdr.detectChanges();
    }
  }

  nextDay(): void {
    this.selectedDate.setDate(this.selectedDate.getDate() + 1);
    this.selectedDate = new Date(this.selectedDate);
    this.cdr.detectChanges();
  }

  prevDay(): void {
    this.selectedDate.setDate(this.selectedDate.getDate() - 1);
    this.selectedDate = new Date(this.selectedDate);
    this.cdr.detectChanges();
  }

  getMonthlyEventTemplateDatas(date: Date): ICalendarEvent | undefined {
    return this.events.find((ev: ICalendarEvent) =>
      this.calendarService.isEqual(ev.startTime, date)
    );
  }

  getDateEvents(date: Date): Array<ICalendarEvent> {
    return this.events.filter((ev) =>
      this.calendarService.isEqual(ev.startTime, date)
    );
  }

  groupByDate(
    events: Array<ICalendarEvent>
  ): Array<{ date: Date; events: ICalendarEvent[] }> {
    const newArrayCalendarEvents: { date: Date; events: ICalendarEvent[] }[] =
      [];
    events.forEach((ev: ICalendarEvent) => {
      const dateExistsIndex = newArrayCalendarEvents.findIndex((newEv) =>
        this.calendarService.isEqual(ev.date, newEv.date)
      );
      if (dateExistsIndex > -1) {
        newArrayCalendarEvents[dateExistsIndex].events.push(ev);
      } else {
        newArrayCalendarEvents.push({
          date: ev.date,
          events: [ev],
        });
      }
    });
    return newArrayCalendarEvents;
  }
}
