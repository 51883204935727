import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ITableColumn } from './i-table-column';
import { DataSource } from '@angular/cdk/collections';
import { ITableConfigs } from './i-table-configs';

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit {
  private pColumns: Array<ITableColumn> = [];

  @Input() datasource!: DataSource<any> | any;
  @Input() set columns(columns: Array<ITableColumn>) {
    this.pColumns = columns;
    this.columnNames = this.pColumns.map((p) => {
      return p.name;
    });
    this.visibleColumnsNames = this.pColumns
      .filter((p) => p.visible)
      .map((p) => p.name);
  }

  get columns(): Array<ITableColumn> {
    return this.pColumns;
  }

  @Input() configs: ITableConfigs = {};

  @Output() changeStatusClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() addRowClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();

  public columnNames: Array<string> = [];
  public visibleColumnsNames: Array<string> = [];
  public sortingDirection: 'asc' | 'desc' = 'asc';
  public sortingActiveFields = '';

  constructor() {}

  ngOnInit(): void {
    const sortedColumn = this.columns.find((c) => c.sorting);
    this.sortingActiveFields = sortedColumn ? sortedColumn.key : '';
  }

  onSortChange(event: any) {
    this.sortingDirection = event.direction == '' ? 'asc' : event.direction;
    this.sortChange.emit({
      active: event.active,
      direction: event.direction == '' ? 'asc' : event.direction,
    });
  }
}
