import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

export const INPUTNUMBER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputNumberComponent),
  multi: true,
};

@Component({
  selector: 'lib-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [INPUTNUMBER_VALUE_ACCESSOR],
})
export class InputNumberComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder = '';
  @Input() min = 0;
  @Input() max = -1;
  @Input() value = 0;
  @Input() label = '';
  @Input() suffix = '';
  @Input() disabled = false;
  @Output() change: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('input', { static: true }) input!: ElementRef;

  onChange = (value: number) => {};
  onTouched = () => {};

  constructor() {}

  ngOnInit(): void {}

  increment(): void {
    const newValue = Number(this.value) + 1;
    if (newValue <= this.max) {
      this.value = newValue;
      this.change.emit(this.value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  decrement(): void {
    const newValue = Number(this.value) - 1;
    if (newValue >= this.min) {
      this.value = newValue;
      this.change.emit(this.value);
    }
  }

  changedValue(): void {
    if (
      this.input.nativeElement.valueAsNumber >= this.min &&
      this.input.nativeElement.valueAsNumber <= this.max
    ) {
      this.value = this.input.nativeElement.valueAsNumber;
      this.change.emit(this.input.nativeElement.valueAsNumber);
    }
  }
  writeValue(value: number): void {
    if (typeof this.value === 'number') {
      this.value = value;
    } else {
      this.value = 0;
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < this.min) {
      return {
        mustBeGreaterThanMin: {
          value,
        },
      };
    }
    if (value > this.max) {
      return {
        mustBeLowerThanMax: {
          value,
        },
      };
    }
    return null;
  }
}
