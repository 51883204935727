<mat-form-field
  floatLabel="always"
  appearance="outline">
  <mat-label>{{label}}</mat-label>
  <span class="input">
    <input matInput
           disabled
           class="input__number"
           #input
           [value]="value"
           type="number"
           (change)="changedValue()"
           [min]="min"
           [max]="max"
           placeholder="{{placeholder}}">
        <span matSuffix class="input__suffix">{{suffix}}</span>
    <div class="input__controls {{disabled ? ' disabled' : ''}}">
      <span class="input__add">
        <span (click)="increment()">
          <mat-icon class="input__icon">arrow_drop_up</mat-icon>
        </span>
      </span>
      <span class="input__sub">
        <span (click)="decrement()">
          <mat-icon class="input__icon">arrow_drop_down</mat-icon>
        </span>
      </span>
    </div>
  </span>

</mat-form-field>
