<span class="calendar">
  <div class="calendar__body">

    <span *ngIf="viewType === 'list'" class="calendar__list">
      <span class="calendar__list__itemheader" *ngFor="let eventGroup of groupByDate(events)">
        <span class="calendar__list__itemheader__content">
          <span class="calendar__list__itemheader__content__date {{((eventGroup.date | dfnsIsToday) ? 'today big' : '')}}">{{eventGroup.date | date:'d'}}</span>
          <span class="calendar__list__itemheader__content__monthAndDay">{{eventGroup.date | date:'MMM'}}, {{eventGroup.date | date:'EEE'}}</span>
        </span>
        <span class="calendar__list__itemevent"  *ngFor="let event of eventGroup.events">
          <ng-container
            *ngIf="eventsConfigs?.list?.template!"
            [ngTemplateOutlet]="eventsConfigs?.list?.template!"
            [ngTemplateOutletContext]="{ $implicit: event}">
          </ng-container>
        </span>
      </span>
    </span>

    <span *ngIf="viewType === 'daily'">
       <table class="calendar__daily">
        <tr class="calendar__daily__dayweeks">
          <td class="calendar__daily__time"></td>
          <td class="calendar__daily__dayweek">
            <span class="calendar__daily__dayweek__dayweek">{{ selectedDate | date:'E' | uppercase}} </span>
            <span [ngClass]="'calendar__daily__date '  + ((selectedDate | dfnsIsToday) ? 'today' : '')">
              {{ selectedDate | date:'d' | uppercase}}</span>
              <lib-calendar-event-area
                [viewType]="viewType"
                [events]="getDateEvents(selectedDate)"
                [timeslotSizeHourInPixel]="timeSlotConfigs?.daily!.timeslotPixelsSize"
                [startTime]="timeSlotConfigs?.daily?.startTimeMinutes!"
                [endTime]="timeSlotConfigs?.daily?.endTimeMinutes!"
                [eventTemplate]="eventsConfigs?.daily?.template!">
            </lib-calendar-event-area>
          </td>
        </tr>
        <tr class="calendar__daily__week" *ngFor="let timeslot of calendarService?.getTimeslots(timeSlotConfigs?.weekly?.timeslotMinutes!, timeSlotConfigs?.weekly?.startTimeMinutes!, timeSlotConfigs?.weekly?.endTimeMinutes!)">
          <td class="calendar__daily__time">
            <span>{{timeslot.startTime | date:'HH:mm':'+0000'}}</span>
          </td>
          <td class="calendar__daily__day">
            <span [ngClass]="(daysOff.indexOf(selectedDate.getDay()) > -1 ? 'dayOff ' : ' ')"
                  [style.height]="timeSlotConfigs?.daily!.timeslotPixelsSize + 'px'"></span>
          </td>
        </tr>
       <lib-calendar-currentTime-bar
         [style.display]="(selectedDate | dfnsIsToday) ? 'block' : 'none'"
         [viewType]="viewType"
         [startTime]="timeSlotConfigs?.daily?.startTimeMinutes!"
         [timeslotSizeHourInPixel]="timeSlotConfigs?.daily?.timeslotPixelsSize!"
       ></lib-calendar-currentTime-bar>
      </table>
    </span>

    <span *ngIf="viewType === 'weekly'">
       <table class="calendar__weekly">
        <tr class="calendar__weekly__dayweeks">
          <td class="calendar__daily__time"></td>
          <td class="calendar__weekly__dayweek" *ngFor="let date of calendarService?.getWeek(selectedDate)?.days">
            <span class="calendar__weekly__dayweek__dayweek">{{ date | date:'E' | uppercase}} </span>
            <span [style.color]="getDateEvents(date) ? eventsConfigs.weekly?.dateNumberColor?.template! : eventsConfigs.weekly?.dateNumberColor?.default!"
                  [ngClass]="'calendar__weekly__date '  + ((date | dfnsIsToday) ? 'today' : '')">{{ date | date:'d' | uppercase}}</span>
           <lib-calendar-event-area
                         [viewType]="viewType"
                         [events]="getDateEvents(date)"
                         [timeslotSizeHourInPixel]="timeSlotConfigs?.weekly!.timeslotPixelsSize"
                         [startTime]="timeSlotConfigs?.weekly?.startTimeMinutes!"
                         [endTime]="timeSlotConfigs?.weekly?.endTimeMinutes!"
                         [eventTemplate]="eventsConfigs?.weekly?.template!">
          </lib-calendar-event-area>
          </td>
        </tr>
        <tr class="calendar__weekly__week" *ngFor="let timeslot of calendarService?.getTimeslots(timeSlotConfigs?.weekly?.timeslotMinutes!, timeSlotConfigs?.weekly?.startTimeMinutes!, timeSlotConfigs?.weekly?.endTimeMinutes!)">
          <td class="calendar__weekly__time">
            <span>{{timeslot.startTime | date:'HH:mm':'+0000'}}</span>
          </td>
          <td [ngClass]="'calendar__weekly__day'" *ngFor="let dayIndex of [0, 1, 2, 3, 4, 5, 6]">
            <div [ngClass]="daysOff.indexOf(dayIndex - 6) > -1 ? 'dayOff' : ''"
                 [style.height]="timeSlotConfigs?.weekly!.timeslotPixelsSize + 'px'">
            </div>
          </td>
        </tr>
        <lib-calendar-currentTime-bar
          [style.display]="(selectedDate | dfnsIsToday) ? 'block' : 'none'"
          [viewType]="viewType"
          [startTime]="timeSlotConfigs?.weekly?.startTimeMinutes!"
          [timeslotSizeHourInPixel]="timeSlotConfigs?.weekly?.timeslotPixelsSize!"
        ></lib-calendar-currentTime-bar>
       </table>
    </span>

    <span *ngIf="viewType === 'monthly'">
      <table class="calendar__monthly">
        <tr class="calendar__monthly__dayweeks">
          <td class="calendar__monthly__dayweek" *ngFor="let day of weekDays">
            {{ day + '_abbr' | translate | uppercase}}
          </td>
        </tr>
        <tr class="calendar__monthly__week" *ngFor="let week of calendarService?.getWeeksInMonth(selectedDate, 1)">
          <td class="calendar__monthly__day" *ngFor="let dayIndex of [0, 1, 2, 3, 4, 5, 6]">
            <span *ngIf="week.days[dayIndex]"
                [ngClass]="(daysOff.indexOf(dayIndex - 6) > -1 ? 'dayOff' : '') + ' ' + (week.days[dayIndex].getMonth() !== selectedDate.getMonth() ? 'calendar__monthly__day__not-this-month ' : 'calendar__monthly__day__current-month ')">
              <span [ngClass]="((week.days[dayIndex] | dfnsIsToday) ? 'today' : '') + ((calendarService?.isLastDateOfPreviousMonthOrFirstDateOfNextMonth(week.days[dayIndex], selectedDate.getMonth())) ? ' large-with-month' : '')"
                    [style.color]="getMonthlyEventTemplateDatas(week.days[dayIndex]) ? eventsConfigs.monthly?.dateNumberColor?.template! : eventsConfigs.monthly?.dateNumberColor?.default!"
                    *ngIf="eventsConfigs.monthly?.showDateNumber!">
                {{week.days[dayIndex] | date: 'd'}}
                {{(calendarService?.isLastDateOfPreviousMonthOrFirstDateOfNextMonth(week.days[dayIndex], selectedDate.getMonth())) ? (week.days[dayIndex] | date:'MMM' | titlecase) : ''}}
              </span>
              <ng-container
                 *ngIf="eventsConfigs?.monthly?.template!"
                 [ngTemplateOutlet]="eventsConfigs?.monthly?.template!"
                 [ngTemplateOutletContext]="{ $implicit: getMonthlyEventTemplateDatas(week.days[dayIndex])}">
              </ng-container>
            </span>
          </td>
        </tr>
      </table>
    </span>
  </div>
</span>
