import { AppState } from '../../../state/app.state';
import { ICustomer } from '../../../../../../api/src/lib/models/i-customer';
import { IPet } from '../../../../../../api/src/lib/models/i-pet';
import { PetType } from '../../../../../../api/src/lib/models/pet-type';
import { IPetBreed } from '../../../../../../api/src/lib/models/i-pet-breed';

export const getCustomers = (state: AppState) => state.customers.customers;
export const getCats = (state: AppState) => state.customers.cats;
export const getDogs = (state: AppState) => state.customers.dogs;

export const customersIsEmpty = (state: AppState) =>
  state.customers.customers.length === 0;

export const getSelectedCustomerListFilter = (state: AppState) => {
  return state.customers.selectedCustomerListFilter;
};

export const getCustomerFormErrors = (state: AppState) => {
  return state.customers.formCustomerErrors;
};

export const getSelectedCustomerId = (state: AppState) => {
  return state.customers.selectedCustomerId;
};

export const getCustomerSortingAndPagination = (state: AppState) => {
  return {
    sorting: state.customers.sortingOwner,
    pagination: state.customers.paginationOwner,
  };
};

export const getDogSortingAndPagination = (state: AppState) => {
  return {
    sorting: state.customers.sortingDog,
    pagination: state.customers.paginationDog,
  };
};

export const getCatSortingAndPagination = (state: AppState) => {
  return {
    sorting: state.customers.sortingCat,
    pagination: state.customers.paginationCat,
  };
};

export const getNewCustomer = (state: AppState) => {
  return state.customers.newCustomer;
};

export const getSelectedPetId = (state: AppState) => {
  return state.customers.selectedPetId;
};

export const getSelectedPetBreed = (state: AppState) => {
  if (state.customers.newPet) {
    return state.customers.newPet.breed;
  }

  const selectedCustomer = state.customers.customers.find(
    (c: ICustomer) => c.id === state.customers.selectedCustomerId
  );

  if (selectedCustomer) {
    const selectedPet = selectedCustomer.pets.find(
      (p) => p.id === state.customers.selectedPetId
    );
    if (selectedPet) {
      return selectedPet.breed;
    } else {
      return {} as IPetBreed;
    }
  } else {
    return {} as IPetBreed;
  }
};

export const getSelectedPet = (state: AppState) => {
  if (state.customers.newPet) {
    return state.customers.newPet;
  }

  const selectedCustomer = state.customers.customers.find(
    (c: ICustomer) => c.id === state.customers.selectedCustomerId
  );

  if (selectedCustomer) {
    const selectedPet = selectedCustomer.pets.find(
      (p) => p.id === state.customers.selectedPetId
    );
    if (selectedPet) {
      return selectedPet;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getCustomerTab = (state: AppState) => {
  return state.customers.customerTab;
};

export const getSelectedCustomer = (state: AppState) => {
  if (state.customers.newCustomer) {
    return state.customers.newCustomer;
  }

  const selectedCustomer = state.customers.customers.find(
    (c: ICustomer) => c.id === state.customers.selectedCustomerId
  );
  if (selectedCustomer) {
    return selectedCustomer;
  } else {
    return undefined;
  }
};

export const getSelectedCustomerPets = (state: AppState) => {
  const selectedCustomer = state.customers.customers.find(
    (c: ICustomer) => c.id === state.customers.selectedCustomerId
  );
  if (selectedCustomer) {
    return selectedCustomer.pets;
  } else {
    return [] as IPet[];
  }
};

export const getSelectedCustomerStatusIsDirty = (state: AppState) => {
  return state.customers.statusIsDirty;
};
