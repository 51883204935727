import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar.component';
import { CalendarService } from './calendar.service';
import { TranslateModule } from '@ngx-translate/core';
import { it } from 'date-fns/locale';

import {
  FormatPipeModule,
  MinPipeModule,
  MaxPipeModule,
  FormatDistanceToNowPipeModule,
  WeekdayNamePipeModule,
  ParsePipeModule,
  DateFnsConfigurationService,
  IsTodayPipeModule,
} from 'ngx-date-fns';
import { CalendarEventAreaComponent } from './calendar-event-area/calendar-event-area.component';
import { CalendarCurrentTimeBarComponent } from './calendar-currentTime-bar/calendar-currentTime-bar.component';

const italianConfig = new DateFnsConfigurationService();
italianConfig.setLocale(it);

@NgModule({
  declarations: [
    CalendarComponent,
    CalendarEventAreaComponent,
    CalendarCurrentTimeBarComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormatPipeModule,
    MinPipeModule,
    MaxPipeModule,
    FormatDistanceToNowPipeModule,
    WeekdayNamePipeModule,
    ParsePipeModule,
    IsTodayPipeModule,
  ],
  providers: [
    CalendarService,
    { provide: DateFnsConfigurationService, useValue: italianConfig },
  ],
  exports: [CalendarComponent],
})
export class CalendarModule {}
