import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CalendarViewType } from '../../../../../ui/src/lib/calendar/models/calendar-view-type';
import { ICalendarTimeslotConfigs } from '../../../../../ui/src/lib/calendar/models/i-calendar-timeslot-configs';
import { CalendarComponent as LibCalendarComponent } from '../../../../../ui/src/lib/calendar/calendar.component';
import { ICalendarEvent } from '../../../../../ui/src/lib/calendar/models/i-calendar-event';
import { combineLatest, Subject } from 'rxjs';
import { AppointmentsService } from '../../../../../api/src/lib/appointments.service';
import { AppState } from '../../state/app.state';
import { Store } from '@ngrx/store';
import { getAppointments } from '../appointments/state/appointments.selector';
import {
  CreateAppointment,
  GetAppointments,
} from '../appointments/state/appointments.action';
import { map, take } from 'rxjs/operators';
import { ICalendarEventConfigs } from '../../../../../ui/src/lib/calendar/models/i-calendar-event-configs';
import { IWorkload } from '../../../../../api/src/lib/models/i-workload';
import { IOperator } from '../../../../../api/src/lib/models/i-operator';
import { UtilsService } from '../../../../../utils/src/lib/utils.service';
import { IAppointment } from 'projects/api/src/lib/models/i-appointment';
import { Router } from '@angular/router';
import { ICalendarFilters } from './i-calendar-filters';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { addHours, differenceInMilliseconds, format } from 'date-fns';
import { getCustomers } from '../customers/state/customers.selector';
import { GetCustomers } from '../customers/state/customers.action';
import { ICustomer } from '../../../../../api/src/lib/models/i-customer';
import { IPetService } from '../../../../../api/src/lib/models/i-pet-service';
import { getServices } from '../settings/services/state/services.selector';
import {
  DeleteService,
  GetServices,
} from '../settings/services/state/services.action';
import { GetOperators } from '../operators/state/operators.action';
import { getOperators } from '../operators/state/operators.selector';
import { ServicesService } from '../../../../../api/src/lib/services.service';
import { IPetServiceTier } from '../../../../../api/src/lib/models/i-pet-service-tier';
import { IPetServiceAddonTier } from '../../../../../api/src/lib/models/i-pet-service-addon-tier';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'romeo-grooming-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @Input() calendarOptions: {
    viewType: CalendarViewType | string;
    selectedDate: Date;
    eventsConfigs: ICalendarEventConfigs;
    daysOff: number[];
    timeslotConfigs: ICalendarTimeslotConfigs;
  } = {} as any;

  @Input() events: Array<ICalendarEvent> = [];
  @Input() filters: ICalendarFilters = {
    appointments: {
      exited: true,
      toBeExited: true,
    },
    operatorsToExclude: [],
  };

  @Output() createdNewAppointment: EventEmitter<{
    template: TemplateRef<any>;
    params: any;
    width: string;
  }> = new EventEmitter<{
    template: TemplateRef<any>;
    params: any;
    width: string;
  }>();

  @Output() closeNewAppointment: EventEmitter<void> = new EventEmitter();

  @ViewChild('monthlyEventTemplate', { static: true })
  monthlyEventTemplate!: TemplateRef<any>;
  @ViewChild('monthlyFreeTimeslotTemplate', { static: true })
  monthlyFreeTimeslotTemplate!: TemplateRef<any>;
  @ViewChild('weeklyEventTemplate', { static: true })
  weeklyEventTemplate!: TemplateRef<any>;
  @ViewChild('listEventTemplate', { static: true })
  listEventTemplate!: TemplateRef<any>;
  @ViewChild('createAppointmentTemplate', { static: true })
  createAppointmentTemplate!: TemplateRef<any>;
  @ViewChild('calendar', { read: LibCalendarComponent, static: true })
  public calendar!: LibCalendarComponent;

  @ViewChild('contextMenu', { static: true })
  contextMenu!: ElementRef;

  @ViewChild('removeAppointmentModal', { static: true })
  removeAppointmentModal!: TemplateRef<any>;

  contextMenuPosition = { x: '0px', y: '0px' };

  public workload$: Subject<Array<IWorkload>> = new Subject();
  public higherCustomerLoad = 0;
  public customers$: Subject<ICustomer[]> = new Subject<ICustomer[]>();
  public services: IPetService[] = [];
  public operators: IOperator[] = [];

  public newAppointmentForm: FormGroup = new FormGroup({
    id: new FormControl('', [Validators.required]),
    customer: new FormControl('', [Validators.required]),
    pet: new FormControl('', [Validators.required]),
    startTime: new FormControl(new Date(), [Validators.required]),
    endTime: new FormControl(new Date(), [Validators.required]),
    duration: new FormControl('', [Validators.required]),
    operator: new FormControl({}, [Validators.required]),
    date: new FormControl(new Date(), [Validators.required]),
    table: new FormControl(0, [Validators.required]),
    bookingNotes: new FormControl('', []),
    firstAppointmentPetInThisGrooming: new FormControl(false, []),
    firstAppointmentPetInEveryOtherGrooming: new FormControl(false, []),
    firstAppointmentOwnerInThisGrooming: new FormControl(false, []),
    firstAppointmentOwnerInEveryOtherGrooming: new FormControl(false, []),
    services: new FormControl([], [Validators.required]),
    totalAmount: new FormControl(0, [Validators.required]),
  });

  public listviewDisabled: boolean = false;
  public sidenavAppointmentIsOpened: boolean = false;
  public formContainErrors = false;
  public contextMenuIsVisible: boolean = false;
  public selectedEvent!: ICalendarEvent | null;

  constructor(
    public store: Store<AppState>,
    public utilityService: UtilsService,
    public router: Router,
    public appointmentsService: AppointmentsService,
    public servicesService: ServicesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.calendarOptions = {
      viewType: CalendarViewType.monthly,
      selectedDate: new Date(),
      daysOff: [0],
      eventsConfigs: {
        monthly: {
          showDateNumber: true,
          template: this.monthlyEventTemplate,
        },
        weekly: {
          template: this.weeklyEventTemplate,
        },
        daily: {
          template: this.weeklyEventTemplate,
        },
        list: {
          template: this.listEventTemplate,
        },
      },
      timeslotConfigs: {
        weekly: {
          timeslotMinutes: 60,
          startTimeMinutes: 6 * 60,
          endTimeMinutes: 21 * 60,
          timeslotPixelsSize: 35,
        },
        daily: {
          timeslotMinutes: 60,
          startTimeMinutes: 6 * 60,
          endTimeMinutes: 21 * 60,
          timeslotPixelsSize: 35,
        },
      },
    };

    this.store.select(getCustomers).subscribe((customers) => {
      this.customers$.next(customers);
    });

    this.store.select(getServices).subscribe((services) => {
      this.services = services;
    });

    this.store.select(getOperators).subscribe((operators) => {
      this.operators = operators;
    });

    if (this.calendarOptions.viewType === CalendarViewType.monthly) {
      this.workload$
        .pipe(
          map((workload: IWorkload[]) => {
            return workload.map((wl) => {
              const startTime = new Date(wl.date);
              const endTime = new Date(wl.date);

              this.higherCustomerLoad =
                this.higherCustomerLoad < wl.customers
                  ? wl.customers
                  : this.higherCustomerLoad;

              return {
                id: new Date(wl.date).getTime().toString(),
                startTime,
                date: new Date(wl.date),
                endTime,
                props: {
                  totalHours: wl.totalHours,
                  freeHours: wl.freeHours,
                  operators: wl.operators,
                  customers: wl.customers,
                },
              };
            });
          })
        )
        .subscribe((events: ICalendarEvent[]) => {
          this.events = events;
        });
    }

    this.fetchDatas(
      this.calendarOptions.selectedDate,
      this.calendarOptions.viewType,
      this.filters
    );
  }

  @HostListener('document:click', ['$event'])
  public clickout(event: any): void {
    if (
      event.target.className.indexOf('weekly-box') > -1 ||
      event.target.className.indexOf('customer-name') > -1 ||
      event.target.className.indexOf('context-menu') > -1 ||
      event.target.className.indexOf('weekly-timerange') > -1
    ) {
      this.contextMenuIsVisible = true;
      this.contextMenu.nativeElement.style.top = '37.5%';
      this.contextMenu.nativeElement.style.left = '42%';
    } else {
      if (this.contextMenuIsVisible) {
        this.closeEventDetail();
      }
    }
  }

  filterAppointmentChanged(filterAppointment: {
    exited?: boolean;
    toBeExited?: boolean;
  }): void {
    const newFilterAppointments = Object.assign(
      {},
      this.filters.appointments,
      filterAppointment
    );
    this.filters = Object.assign({}, this.filters, {
      appointments: newFilterAppointments,
    });
    this.fetchDatas(
      this.calendarOptions.selectedDate,
      this.calendarOptions.viewType,
      this.filters
    );
  }

  filterOperatorChanged(operatorFilter: {
    id: string;
    checked: boolean;
  }): void {
    const newFilterOperatorToExclude = JSON.parse(
      JSON.stringify(this.filters.operatorsToExclude)
    );
    if (!operatorFilter.checked) {
      newFilterOperatorToExclude.push(operatorFilter.id);
    } else {
      const opIndex = newFilterOperatorToExclude.findIndex(
        (op: any) => op === operatorFilter.id
      );
      newFilterOperatorToExclude.splice(opIndex, 1);
    }
    this.filters = Object.assign({}, this.filters, {
      operatorsToExclude: newFilterOperatorToExclude,
    });
    this.fetchDatas(
      this.calendarOptions.selectedDate,
      this.calendarOptions.viewType,
      this.filters
    );
  }

  fetchDatas(
    currentDate: Date,
    viewType: CalendarViewType | string,
    filters: ICalendarFilters
  ): void {
    if (viewType === 'monthly') {
      this.appointmentsService
        .getMonthLoad(
          currentDate.getMonth(),
          currentDate.getFullYear(),
          filters
        )
        .subscribe((workload) => {
          this.workload$.next(workload);
        });
      // workload
    }
    if (viewType === 'daily' || viewType === 'weekly' || viewType === 'list') {
      this.store.dispatch(
        new GetAppointments(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          this.filters
        )
      );
      this.store
        .select(getAppointments)
        .pipe(
          take(1),
          map((appointments: IAppointment[]) => {
            return this.formatWeeklyOrDailyAppointments(appointments);
          })
        )
        .subscribe((appointments: ICalendarEvent[]) => {
          this.events = appointments;
        });
      // daily events
    }
  }

  prev(): void {
    switch (this.calendar.viewType) {
      case 'daily': {
        this.calendar.prevDay();
        return;
      }
      case 'weekly': {
        this.calendar.prevWeek();
        return;
      }
      case 'monthly': {
        this.calendar.prevMonth();
        return;
      }
      default: {
        return;
      }
    }
  }

  next(): void {
    switch (this.calendar.viewType) {
      case 'daily': {
        this.calendar.nextDay();
        return;
      }
      case 'weekly': {
        this.calendar.nextWeek();
        return;
      }
      case 'monthly': {
        this.calendar.nextMonth();
        return;
      }
      default: {
        return;
      }
    }
  }

  sumOperatorsHours(
    operators: { operator: IOperator[]; workload: number }[]
  ): number {
    let sum = 0;
    operators.forEach((op: { operator: IOperator[]; workload: number }) => {
      sum += op.workload;
    });
    return sum;
  }

  getColorWithAlpha(color: string, alpha: number): string {
    return this.utilityService.hexToRgbA(color, alpha);
  }

  openSidenavAppointment(): void {
    this.store.dispatch(new GetOperators());
    this.store.dispatch(new GetServices());
    this.listviewDisabled = true;
    this.sidenavAppointmentIsOpened = true;
    this.calendarOptions.viewType = CalendarViewType.monthly;
    const startTime = new Date();
    const endTime = addHours(startTime, 1);
    this.store.dispatch(
      new CreateAppointment({
        startTime: format(startTime, 'HH:mm'),
        endTime: format(endTime, 'HH:mm'),
      } as IAppointment)
    );
    this.appointmentsService
      .getFreeTimeslots(
        this.calendarOptions.selectedDate,
        this.calendarOptions.viewType,
        Math.abs(differenceInMilliseconds(startTime, endTime))
      )
      .subscribe((timeslots: Array<ICalendarEvent>) => {
        this.calendar.events = timeslots;
        if (this.calendarOptions.viewType === 'monthly') {
          const eventsConfigs = Object.assign(
            {},
            this.calendarOptions.eventsConfigs
          );
          if (eventsConfigs && eventsConfigs.monthly) {
            eventsConfigs.monthly.template = this.monthlyFreeTimeslotTemplate;
            eventsConfigs.monthly.dateNumberColor = {
              template: '#1BC8D6',
              default: '',
            };
          }

          this.calendarOptions = Object.assign(this.calendarOptions, {
            eventsConfigs,
          });
        }
      });
    this.createdNewAppointment.emit({
      template: this.createAppointmentTemplate,
      params: {},
      width: '300px',
    });
  }

  closeSidenavAppointment(): void {
    this.sidenavAppointmentIsOpened = false;
    this.listviewDisabled = false;
    this.ngOnInit();
    this.closeNewAppointment.emit();
  }

  fetchCustomers(event: string): void {
    if (event.length > 1) {
      this.store.dispatch(
        new GetCustomers(undefined, undefined, ['fullName'], ['asc'], event)
      );
    }
  }

  selectDate(
    date: Date,
    startTime?: Date,
    endTime?: Date,
    services?: (IPetServiceTier | IPetServiceAddonTier)[],
    id?: string
  ): void {
    if (
      this.calendarOptions.viewType === CalendarViewType.weekly ||
      this.calendarOptions.viewType === CalendarViewType.daily
    ) {
      this.newAppointmentForm.controls.id.setValue(id);
      this.newAppointmentForm.controls.date.setValue(date);
      if (startTime) {
        this.newAppointmentForm.controls.startTime.setValue(
          this.utilityService.convertMinutesToHHMM(
            startTime.getHours() * 60 + startTime.getMinutes()
          )
        );
      }
      if (endTime) {
        this.newAppointmentForm.controls.endTime.setValue(
          this.utilityService.convertMinutesToHHMM(
            endTime.getHours() * 60 + endTime.getMinutes()
          )
        );
      }

      const duration = this.servicesService.computeTotalDuration(services);
      const totalAmount = this.servicesService.computeTotalAmount(services);
      if (duration) {
        this.newAppointmentForm.controls.duration.setValue(
          this.utilityService.convertMinutesToHHMM(duration)
        );
      }
      this.newAppointmentForm.controls.totalAmount.setValue(totalAmount);
      this.formContainErrors = false;
      return;
    }

    if (this.calendarOptions.viewType === CalendarViewType.monthly) {
      this.newAppointmentForm.controls.id.reset();
      this.newAppointmentForm.controls.startTime.reset();
      this.newAppointmentForm.controls.endTime.reset();
      this.newAppointmentForm.controls.duration.reset();
      this.newAppointmentForm.controls.totalAmount.reset();
      this.newAppointmentForm.controls.date.setValue(date);
    }

    this.calendarOptions.viewType = CalendarViewType.weekly;
    this.calendarOptions.selectedDate = date;
    this.fetchDatas(date, 'weekly', this.filters);
    combineLatest(
      this.appointmentsService.getFreeTimeslots(
        this.calendarOptions.selectedDate,
        this.calendarOptions.viewType
      ),
      this.store.select(getAppointments).pipe(
        take(1),
        map((appointments: IAppointment[]) => {
          return this.formatWeeklyOrDailyAppointments(appointments);
        })
      )
    )
      .pipe(take(1))
      .subscribe(([timeslots, appointments]: any) => {
        appointments.map((a: IAppointment) => {
          return {
            id: a.id,
            startTime: a.startTime,
            endTime: a.endTime,
            date: a.date,
            props: a,
          } as ICalendarEvent;
        });
        const merged = timeslots;
        merged.push(...appointments);
        this.events = merged;
        if (this.calendarOptions.viewType === 'weekly') {
          const eventsConfigs = Object.assign(
            {},
            this.calendarOptions.eventsConfigs
          );
          if (eventsConfigs && eventsConfigs.weekly) {
            eventsConfigs.weekly.template = this.weeklyEventTemplate;
          }
        }
      });
    this.calendar.gotoWeek(date);
  }

  public editAppointment(appointment: ICalendarEvent | null): void {
    this.openSidenavAppointment();
    this.selectedEvent = appointment;
    this.newAppointmentForm.reset(appointment);
    //TODO popolare il form con l'appuntamento selezionato, cambiare il titolo del form Modifica appuntamento e il bottone in Modifica
  }

  public deleteAppointment(appointment: ICalendarEvent | null): void {
    if (appointment) {
      this.utilityService.showModal(
        this.removeAppointmentModal,
        {},
        () => {
          this.appointmentsService
            .removeAppointment(appointment.id)
            .subscribe(() => {
              this.snackBar.open('Appuntamento rimosso', '', {
                duration: 2000,
                panelClass: ['success-api'],
              });
            });
        },
        () => null
      );
    }
  }

  public formatWeeklyOrDailyAppointments(
    appointments: Array<IAppointment>
  ): Array<any> {
    return appointments.map((ap: IAppointment) => {
      const startTime = new Date(ap.date);
      const endTime = new Date(ap.date);
      const startTimeDatetime = ap.startTime.split(':');
      startTime.setHours(Number(startTimeDatetime[0]));
      startTime.setMinutes(Number(startTimeDatetime[1]));

      const endTimeDatetime = ap.endTime.split(':');
      endTime.setHours(Number(endTimeDatetime[0]));
      endTime.setMinutes(Number(endTimeDatetime[1]));

      return {
        id: ap.id,
        startTime,
        endTime,
        date: new Date(ap.date),
        props: {
          registered: ap.registered,
          owner: ap.owner,
          services: ap.services,
          pet: ap.pet,
          firstAppointmentPet: ap.firstAppointmentPet,
          firstAppointmentOwner: ap.firstAppointmentOwner,
          operator: ap.operator,
          bookingNotes: ap.bookingNotes,
          table: ap.table,
        },
      };
    });
  }

  public createNewAppointment(): void {
    if (this.newAppointmentForm.valid && !this.formContainErrors) {
      this.appointmentsService
        .createAppointment(this.newAppointmentForm.getRawValue())
        .subscribe((res) => {
          this.newAppointmentForm = new FormGroup({
            id: new FormControl('', [Validators.required]),
            customer: new FormControl('', [Validators.required]),
            pet: new FormControl('', [Validators.required]),
            startTime: new FormControl(new Date(), [Validators.required]),
            endTime: new FormControl(new Date(), [Validators.required]),
            duration: new FormControl('', [Validators.required]),
            operator: new FormControl({}, [Validators.required]),
            date: new FormControl(new Date(), [Validators.required]),
            table: new FormControl(0, [Validators.required]),
            bookingNotes: new FormControl('', []),
            firstAppointmentPetInThisGrooming: new FormControl(false, []),
            firstAppointmentPetInEveryOtherGrooming: new FormControl(false, []),
            firstAppointmentOwnerInThisGrooming: new FormControl(false, []),
            firstAppointmentOwnerInEveryOtherGrooming: new FormControl(
              false,
              []
            ),
            services: new FormControl([], [Validators.required]),
            totalAmount: new FormControl(0, [Validators.required]),
          });
          if (res) {
            this.closeSidenavAppointment();

            this.snackBar.open(
              'Complimenti! Appuntamento preso con successo',
              '',
              {
                duration: 2000,
                panelClass: ['success-api'],
              }
            );
          } else {
          }
        });
    }
  }

  public newAppointmentFormChanged(event: {
    errors: boolean;
    change: any;
  }): void {
    if (event.change) {
      this.formContainErrors = event.errors;
      if (
        (event.change.services.length === 1 &&
          this.utilityService.isEmptyObject(event.change.services[0])) ||
        this.utilityService.isEmptyObject(event.change.operator)
      ) {
        this.formContainErrors = true;
      }
      this.newAppointmentForm.setValue(event.change);
    }
  }

  public selectEvent(calEvent: ICalendarEvent): void {
    this.selectedEvent = calEvent;
  }

  public closeEventDetail(): void {
    this.contextMenuIsVisible = false;
    this.selectedEvent = null;
  }
}
