import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { IApiResponse } from './models/i-api-response';
import { IAppointment } from './models/i-appointment';
import { IPet } from './models/i-pet';
import { ICustomer } from './models/i-customer';
import { IOperator } from './models/i-operator';
import { IWorkload } from './models/i-workload';
import { ICalendarFilters } from '../../../grooming/src/app/home/calendar/i-calendar-filters';
import { CalendarViewType } from '../../../ui/src/lib/calendar/models/calendar-view-type';
import { ICalendarEvent } from '../../../ui/src/lib/calendar/models/i-calendar-event';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  public toBeExited$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public operators$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(
    private readonly apiService: ApiService,
    private readonly snackBar: MatSnackBar
  ) {}

  public createAppointment(
    appointment: IAppointment
  ): Observable<IAppointment> {
    return of(appointment as IAppointment);
  }

  public removeAppointment(idAppointment: string): Observable<IAppointment> {
    return of({} as IAppointment);
  }

  public getFreeTimeslots(
    selectedDate: Date,
    viewType: string | CalendarViewType,
    duration: number = 60
  ): Observable<Array<ICalendarEvent>> {
    return of([
      {
        id: Math.random().toString(),
        startTime: new Date(2021, 5, 11, 9, 0),
        date: new Date(2021, 5, 11),
        endTime: new Date(2021, 5, 11, 10, 0),
        props: {
          free: true,
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              duration: 30,
            },
            {
              id: '2',
              name: 'Taglio unghie',
              price: 10.5,
              duration: 60,
            },
          ],
        },
      },
      {
        id: Math.random().toString(),
        startTime: new Date(2021, 5, 11, 10, 0),
        date: new Date(2021, 5, 11),
        endTime: new Date(2021, 5, 11, 11, 0),
        props: {
          free: true,
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              duration: 30,
            },
            {
              id: '2',
              name: 'Taglio unghie',
              price: 10.5,
              duration: 60,
            },
          ],
        },
      },
      {
        id: Math.random().toString(),
        startTime: new Date(2021, 5, 12, 9, 0),
        date: new Date(2021, 5, 12),
        endTime: new Date(2021, 5, 12, 10, 0),
        props: {
          free: true,
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              duration: 30,
            },
            {
              id: '2',
              name: 'Taglio unghie',
              price: 10.5,
              duration: 60,
            },
          ],
        },
      },
      {
        id: Math.random().toString(),
        startTime: new Date(2021, 5, 15, 13, 0),
        date: new Date(2021, 5, 15),
        endTime: new Date(2021, 5, 15, 14, 30),
        props: {
          free: true,
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              duration: 30,
            },
            {
              id: '2',
              name: 'Taglio unghie',
              price: 10.5,
              duration: 60,
            },
          ],
        },
      },
    ]);
  }

  public getMonthLoad(
    month: number,
    year: number,
    filters: any
  ): Observable<Array<IWorkload>> {
    return of({
      data: [
        {
          date: '06/01/2021',
          freeHours: 8,
          totalHours: 16,
          customers: 4,
          operators: [
            {
              operator: {
                id: 'aaaaaa',
                color: '#0000ff',
                firstName: 'Giorgio',
                lastName: 'Bianchi',
              },
              workload: 2,
            },
            {
              operator: {
                id: 'bbbbbb',
                color: '#ffc0cb',
                firstName: 'Filippo',
                lastName: 'Carini',
              },
              workload: 1,
            },
            {
              operator: {
                id: 'cccccc',
                color: '#FFD800',
                firstName: 'Giovannino',
                lastName: 'Gennari',
              },
              workload: 1,
            },
          ],
        },
        {
          date: '06/06/2021',
          freeHours: 3,
          totalHours: 16,
          customers: 7,
          operators: [
            {
              operator: {
                id: 'aaaaaa',
                color: '#0000ff',
                firstName: 'Giorgio',
                lastName: 'Bianchi',
              },
              workload: 1,
            },
            {
              operator: {
                id: 'bbbbbb',
                color: '#ffc0cb',
                firstName: 'Filippo',
                lastName: 'Carini',
              },
              workload: 4,
            },
            {
              operator: {
                id: 'cccccc',
                color: '#FFD800',
                firstName: 'Giovannino',
                lastName: 'Gennari',
              },
              workload: 3,
            },
          ],
        },
      ],
    } as any).pipe(
      map((res: IApiResponse) => {
        this.toBeExited$.next(3);
        const operators: IOperator[] = [];
        res.data.forEach((e: IWorkload) => {
          e.operators.forEach((op: any) => {
            if (!operators.find((o) => o.id === op.operator.id)) {
              operators.push(op.operator);
            }
          });
        });
        this.operators$.next(operators);
        const workload = res.data as IWorkload[];
        return workload;
      })
    );
  }

  public fetchAll(
    offset?: number,
    limit?: number,
    sort?: Array<string>,
    order?: Array<'asc' | 'desc'>,
    search?: string,
    filters?: ICalendarFilters
  ): Observable<Array<IAppointment>> {
    let queryStringObj = {
      limit: limit ? limit.toString() : '',
      offset: offset ? offset.toString() : '',
      'sort[]': sort ? sort.toString() : '',
      'order[]': order ? order.toString() : '',
      filters: filters ? filters.toString() : '',
    } as any;

    if (!offset) {
      delete queryStringObj.offset;
    }

    if (!sort) {
      delete queryStringObj['sort[]'];
    }

    if (!order) {
      delete queryStringObj['order[]'];
    }

    if (search) {
      queryStringObj = {
        search,
      };
    }

    /*return this.apiService.get('appointments?',{
       params: new HttpParams({
         fromObject: queryStringObj
       })})*/
    return of({
      data: [
        {
          id: '123',
          date: '06/06/2021',
          startTime: '09:00',
          endTime: '10:00',
          bookingNotes: 'Il gatto ha una zampa rotta',
          firstAppointmentPet: {
            inThisGrooming: true,
            inEveryOtherGrooming: false,
          },
          firstAppointmentOwner: {
            inThisGrooming: false,
            inEveryOtherGrooming: false,
          },
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              addons: [
                {
                  id: '1',
                  name: 'Balsamo',
                  price: 60.5,
                },
                {
                  id: '1',
                  name: 'Taglio unghie',
                  price: 60.5,
                },
              ],
            },
            {
              id: '2',
              name: 'Balsamo',
              price: 7.5,
            },
          ],
          pet: {
            id: '609e3714c7d370480c1028a9',
            name: 'Ginetto',
            rating: 0,
            type: 'cat',
          } as IPet,
          registered: true,
          table: 1,
          operator: {
            firstName: 'Luigi',
            lastName: 'Bianchi',
            color: '#FFD800',
            fullName: 'Luigi Bianchi',
            id: '609e3765c7d370480c1028aa',
          } as IOperator,
          owner: {
            id: '609dede6c7d370480c1028a8',
            firstName: 'Lorenzo',
            lastName: 'Iovino',
          } as ICustomer,
        },
        {
          id: '6546',
          date: '06/08/2021',
          startTime: '19:00',
          endTime: '21:00',
          bookingNotes: 'Il gatto ha una zampa rotta',
          firstAppointmentPet: {
            inThisGrooming: true,
            inEveryOtherGrooming: false,
          },
          firstAppointmentOwner: {
            inThisGrooming: false,
            inEveryOtherGrooming: false,
          },
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              addons: [
                {
                  id: '1',
                  name: 'Balsamo',
                  price: 60.5,
                },
                {
                  id: '1',
                  name: 'Taglio unghie',
                  price: 60.5,
                },
              ],
            },
          ],
          pet: {
            id: '609e3714c7d370480c1028a9',
            name: 'Ginetto',
            rating: 0,
            type: 'cat',
          } as IPet,
          registered: true,
          table: 1,
          operator: {
            firstName: 'Luigi',
            lastName: 'Bianchi',
            color: '#FFD800',
            fullName: 'Luigi Bianchi',
            id: '609e3765c7d370480c1028a1',
          } as IOperator,
          owner: {
            id: '609dede6c7d370480c1028a8',
            firstName: 'Lorenzo',
            lastName: 'Iovino',
          } as ICustomer,
        },
        {
          id: '123',
          date: '06/08/2021',
          startTime: '13:00',
          endTime: '14:30',
          bookingNotes: 'Il gatto ha una zampa rotta',
          firstAppointmentPet: {
            inThisGrooming: true,
            inEveryOtherGrooming: false,
          },
          firstAppointmentOwner: {
            inThisGrooming: false,
            inEveryOtherGrooming: false,
          },
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              addons: [
                {
                  id: '1',
                  name: 'Balsamo',
                  price: 60.5,
                },
                {
                  id: '1',
                  name: 'Taglio unghie',
                  price: 60.5,
                },
              ],
            },
            {
              id: '2',
              name: 'Balsamo',
              price: 7.5,
            },
          ],
          pet: {
            id: '609e3714c7d370480c1028a9',
            name: 'Ginetto',
            rating: 0,
            type: 'cat',
          } as IPet,
          registered: false,
          table: 1,
          operator: {
            firstName: 'Luigi',
            lastName: 'Bianchi',
            color: '#FFD800',
            fullName: 'Luigi Bianchi',
            id: '609e3765c7d370480c1028aa',
          } as IOperator,
          owner: {
            id: '609dede6c7d370480c1028a8',
            firstName: 'Lorenzo',
            lastName: 'Iovino',
          } as ICustomer,
        },
        {
          id: '6546',
          date: '06/08/2021',
          startTime: '15:00',
          endTime: '16:30',
          bookingNotes: 'Il gatto ha una zampa rotta',
          firstAppointmentPet: {
            inThisGrooming: true,
            inEveryOtherGrooming: false,
          },
          firstAppointmentOwner: {
            inThisGrooming: false,
            inEveryOtherGrooming: false,
          },
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              addons: [
                {
                  id: '1',
                  name: 'Balsamo',
                  price: 60.5,
                },
                {
                  id: '1',
                  name: 'Taglio unghie',
                  price: 60.5,
                },
              ],
            },
            {
              id: '2',
              name: 'Balsamo',
              price: 7.5,
            },
          ],
          pet: {
            id: '609e3714c7d370480c1028a9',
            name: 'Ginetto',
            rating: 0,
            type: 'cat',
          } as IPet,
          registered: true,
          table: 1,
          operator: {
            firstName: 'Luigi',
            lastName: 'Bianchi',
            color: '#dadada',
            fullName: 'Luigi Bianchi',
            id: '609e3765c7d370480c1028aa',
          } as IOperator,
          owner: {
            id: '609dede6c7d370480c1028a8',
            firstName: 'Lorenzo',
            lastName: 'Iovino',
          } as ICustomer,
        },
        {
          id: '35455',
          date: '06/28/2021',
          startTime: '07:00',
          endTime: '11:30',
          bookingNotes: 'Il gatto ha una zampa rotta',
          firstAppointmentPet: {
            inThisGrooming: true,
            inEveryOtherGrooming: false,
          },
          firstAppointmentOwner: {
            inThisGrooming: false,
            inEveryOtherGrooming: false,
          },
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              addons: [
                {
                  id: '1',
                  name: 'Balsamo',
                  price: 60.5,
                },
                {
                  id: '1',
                  name: 'Taglio unghie',
                  price: 60.5,
                },
              ],
            },
            {
              id: '2',
              name: 'Balsamo',
              price: 7.5,
            },
          ],
          pet: {
            id: '609e3714c7d370480c1028a9',
            name: 'Ginetto',
            rating: 0,
            type: 'cat',
          } as IPet,
          registered: true,
          table: 1,
          operator: {
            firstName: 'Luigi',
            lastName: 'Bianchi',
            color: '#dadada',
            fullName: 'Luigi Bianchi',
            id: '609e3765c7d370480c1028aa',
          } as IOperator,
          owner: {
            id: '609dede6c7d370480c1028a8',
            firstName: 'Lorenzo',
            lastName: 'Iovino',
          } as ICustomer,
        },
        {
          id: '6546',
          date: '07/04/2021',
          startTime: '19:00',
          endTime: '21:00',
          bookingNotes: 'Il gatto ha una zampa rotta',
          firstAppointmentPet: {
            inThisGrooming: true,
            inEveryOtherGrooming: false,
          },
          firstAppointmentOwner: {
            inThisGrooming: false,
            inEveryOtherGrooming: false,
          },
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              addons: [
                {
                  id: '1',
                  name: 'Balsamo',
                  price: 60.5,
                },
                {
                  id: '1',
                  name: 'Taglio unghie',
                  price: 60.5,
                },
              ],
            },
          ],
          pet: {
            id: '609e3714c7d370480c1028a9',
            name: 'Ginetto',
            rating: 0,
            type: 'cat',
          } as IPet,
          registered: true,
          table: 1,
          operator: {
            firstName: 'Luigi',
            lastName: 'Bianchi',
            color: '#FFD800',
            fullName: 'Luigi Bianchi',
            id: '609e3765c7d370480c1028a1',
          } as IOperator,
          owner: {
            id: '609dede6c7d370480c1028a8',
            firstName: 'Lorenzo',
            lastName: 'Iovino',
          } as ICustomer,
        },
        {
          id: '123',
          date: '07/04/2021',
          startTime: '06:00',
          endTime: '09:30',
          bookingNotes: 'Il gatto ha una zampa rotta',
          firstAppointmentPet: {
            inThisGrooming: true,
            inEveryOtherGrooming: false,
          },
          firstAppointmentOwner: {
            inThisGrooming: false,
            inEveryOtherGrooming: false,
          },
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              addons: [
                {
                  id: '1',
                  name: 'Balsamo',
                  price: 60.5,
                },
                {
                  id: '1',
                  name: 'Taglio unghie',
                  price: 60.5,
                },
              ],
            },
            {
              id: '2',
              name: 'Balsamo',
              price: 7.5,
            },
          ],
          pet: {
            id: '609e3714c7d370480c1028a9',
            name: 'Ginetto',
            rating: 0,
            type: 'cat',
          } as IPet,
          registered: false,
          table: 1,
          operator: {
            firstName: 'Luigi',
            lastName: 'Bianchi',
            color: '#FFD800',
            fullName: 'Luigi Bianchi',
            id: '609e3765c7d370480c1028aa',
          } as IOperator,
          owner: {
            id: '609dede6c7d370480c1028a8',
            firstName: 'Lorenzo',
            lastName: 'Iovino',
          } as ICustomer,
        },
        {
          id: '6546',
          date: '06/30/2021',
          startTime: '15:00',
          endTime: '16:30',
          bookingNotes: 'Il gatto ha una zampa rotta',
          firstAppointmentPet: {
            inThisGrooming: true,
            inEveryOtherGrooming: false,
          },
          firstAppointmentOwner: {
            inThisGrooming: false,
            inEveryOtherGrooming: false,
          },
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              addons: [
                {
                  id: '1',
                  name: 'Balsamo',
                  price: 60.5,
                },
                {
                  id: '1',
                  name: 'Taglio unghie',
                  price: 60.5,
                },
              ],
            },
            {
              id: '2',
              name: 'Balsamo',
              price: 7.5,
              addons: [
                {
                  id: '1',
                  name: 'Balsamo',
                  price: 60.5,
                },
                {
                  id: '1',
                  name: 'Taglio unghie',
                  price: 60.5,
                },
              ],
            },
          ],
          pet: {
            id: '609e3714c7d370480c1028a9',
            name: 'Ginetto',
            rating: 0,
            type: 'cat',
          } as IPet,
          registered: true,
          table: 1,
          operator: {
            firstName: 'Luigi',
            lastName: 'Bianchi',
            color: '#dadada',
            fullName: 'Luigi Bianchi',
            id: '609e3765c7d370480c1028aa',
          } as IOperator,
          owner: {
            id: '609dede6c7d370480c1028a8',
            firstName: 'Lorenzo',
            lastName: 'Iovino',
          } as ICustomer,
        },
        {
          id: '35455',
          date: '06/30/2021',
          startTime: '18:00',
          endTime: '18:30',
          bookingNotes: 'Il gatto ha una zampa rotta',
          firstAppointmentPet: {
            inThisGrooming: true,
            inEveryOtherGrooming: false,
          },
          firstAppointmentOwner: {
            inThisGrooming: false,
            inEveryOtherGrooming: false,
          },
          services: [
            {
              id: '1',
              name: 'Bagno e tosatura',
              price: 60.5,
              addons: [
                {
                  id: '1',
                  name: 'Balsamo',
                  price: 60.5,
                },
                {
                  id: '1',
                  name: 'Taglio unghie',
                  price: 60.5,
                },
              ],
            },
            {
              id: '2',
              name: 'Balsamo',
              price: 7.5,
            },
          ],
          pet: {
            id: '609e3714c7d370480c1028a9',
            name: 'Ginetto',
            rating: 0,
            type: 'cat',
          } as IPet,
          registered: true,
          table: 1,
          operator: {
            firstName: 'Luigi',
            lastName: 'Bianchi',
            color: '#dadada',
            fullName: 'Luigi Bianchi',
            id: '609e3765c7d370480c1028aa',
          } as IOperator,
          owner: {
            id: '609dede6c7d370480c1028a8',
            firstName: 'Lorenzo',
            lastName: 'Iovino',
          } as ICustomer,
        },
      ],
    } as IApiResponse).pipe(
      map((res: IApiResponse) => {
        this.toBeExited$.next(3);
        const operators: IOperator[] = [];
        res.data.forEach((e: IAppointment) => {
          if (!operators.find((op) => op.id === e.operator.id)) {
            operators.push(e.operator);
          }
        });
        this.operators$.next(operators);
        const appointments = res.data as IAppointment[];
        return appointments;
      })
    );
  }

  public fetchById(id: string): Observable<IAppointment> {
    // return this.apiService.get('appointments/' + id)
    return of({
      id: '123',
      date: '10/05/2021',
      startTime: '09:00',
      endTime: '10:00',
      bookingNotes: 'Il gatto ha una zampa rotta',
      firstAppointmentPet: {
        inThisGrooming: true,
        inEveryOtherGrooming: false,
      },
      firstAppointmentOwner: {
        inThisGrooming: false,
        inEveryOtherGrooming: false,
      },
      services: [
        {
          id: '1',
          name: 'Bagno e tosatura',
          price: 60.5,
        },
        {
          id: '2',
          name: 'Balsamo',
          price: 7.5,
        },
      ],
      pet: {
        id: '609e3714c7d370480c1028a9',
        name: 'Ginetto',
        rating: 0,
        type: 'cat',
      } as IPet,
      operator: {
        firstName: 'Luigi',
        lastName: 'Bianchi',
        color: '#FFD800',
        fullName: 'Luigi Bianchi',
        id: '609e3765c7d370480c1028aa',
      } as IOperator,
      owner: {
        id: '609dede6c7d370480c1028a8',
        firstName: 'Lorenzo',
        lastName: 'Iovino',
      } as ICustomer,
    } as any).pipe(
      map((res: any) => {
        return res as IAppointment;
      })
    );
  }

  public register(appointment: IAppointment): Observable<IAppointment> {
    if (!appointment.id) {
      throw new Error('appointment.id is mandatory');
    }

    return this.apiService
      .post('appointment/' + appointment.id, appointment)
      .pipe(
        map((res: any) => {
          return res as IAppointment;
        }),
        tap((res) => {
          if (res) {
            this.snackBar.open('Appuntamento registrato correttamente', '', {
              duration: 2000,
              panelClass: ['success-api'],
            });
          }
        })
      );
  }
}
