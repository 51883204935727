import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from '../../../../../../api/src/lib/user.service';
import { IGroomingProfile } from '../../../../../../api/src/lib/models/i-grooming-profile';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'romeo-grooming-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
  @Input() user: IGroomingProfile = this.route.snapshot.data.user;

  public profileForm = new FormGroup({
    groomingName: new FormControl(''),
    numberOfTables: new FormControl(0),
    name: new FormControl(''),
    surname: new FormControl(''),
    email: new FormControl(''),
    password: new FormControl(''),
    telephone: new FormControl(''),
    mobilePhone: new FormControl(''),
  });

  groomingImage: any;

  constructor(public userService: UserService, public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.profileForm.patchValue(this.user);
  }

  public onSubmit(): void {
    this.userService.saveProfile(this.profileForm.getRawValue()).subscribe();
  }

  public saveImage(image: { base64: string; file: File } | any): void {
    this.groomingImage = image.base64;
    this.userService.uploadImage(image.file).subscribe();
  }
}
