import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  showModal(
    template: TemplateRef<any> | ComponentType<any>,
    data: any,
    callbackTrue?: any,
    callbackFalse?: any,
    callbackCancel?: any
  ): void {
    const dialogRef = this.dialog.open(template, { data });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === null && callbackCancel) {
        return callbackCancel(result);
      }
      if (result) {
        callbackTrue(result);
      } else {
        callbackFalse(result);
      }
    });
  }

  showToast(msg: string, type: 'success' | 'error' | 'warning') {
    this.snackBar.open(msg, '', {
      duration: 2000,
      panelClass: [type + '-api'],
    });
  }

  convertMinutesToHHMM(timeInMinutes: number): string {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = Math.floor(timeInMinutes % 60);
    return (
      (hours < 10 ? '0' : '') +
      hours +
      ':' +
      (minutes < 10 ? '0' : '') +
      minutes
    );
  }

  convertHHMMtoMinutes(hhmmDate: string): number {
    const durationSplitted = hhmmDate.split(':');
    const hours = Number(durationSplitted[0]);
    const minutes = Number(durationSplitted[1]);
    return hours * 60 + minutes;
  }

  showFormError(): void {
    this.snackBar.open('Compila tutti i campi', '', {
      duration: 2000,
      panelClass: ['error-api'],
    });
  }

  hexToRgbA(hex: string, alpha: number): string {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return (
        'rgba(' +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        ',' +
        alpha +
        ')'
      );
    } else {
      return '';
    }
  }

  isEmptyObject(obj: any): boolean {
    return JSON.stringify(obj) === '{}';
  }
}
