import { Action } from '@ngrx/store';
import { IPetService } from '../../../../../../../api/src/lib/models/i-pet-service';
import { PetType } from '../../../../../../../api/src/lib/models/pet-type';
import { IPetServiceTier } from '../../../../../../../api/src/lib/models/i-pet-service-tier';
import { IPetServiceAddon } from '../../../../../../../api/src/lib/models/i-pet-service-addon';
import { IPetServiceAddonTier } from '../../../../../../../api/src/lib/models/i-pet-service-addon-tier';

export const GET_SERVICES = '[Services] Get services';
export const GET_SERVICES_SUCCESS = '[Services] Get services success';

export const CHANGE_PETTYPE = '[Services] Change petType';

export const CREATE_SERVICE = '[Services] Create service';
export const CREATE_SERVICE_SUCCESS = '[Services] Create service success';

export const SAVE_SERVICE = '[Services] Save selected service';
export const SAVE_SERVICE_SUCCESS = '[Services] Save selected service success';

export const ADD_TIER_IN_SELECTED_SERVICE =
  '[Services] Add tier in selected service';
export const UPDATE_TIER_IN_SELECTED_SERVICE =
  '[Service] Update tier in selected service';
export const ADD_ADDON_IN_SELECTED_SERVICE =
  '[Service] Add addon in selected service';
export const ADD_TIER_IN_SELECTED_ADDON_OF_SELECTED_SERVICE =
  '[Service] Add tier in selected addon of selected service';
export const UPDATE_TIER_IN_SELECTED_ADDON_OF_SELECTED_SERVICE =
  '[Service] Update tier in selected addon of selected service';
export const ADD_ADDON_TIER_IN_SELECTED_ADDON =
  '[Service] Add addon tier in selected addon';

export const SELECT_ADDON = '[Services] Select addon';
export const SELECT_ADDON_SUCCESS = '[Services] Select addon success';

export const SELECT_ADDON_TIER = '[Services] Select addon tier';

export const SET_ADDON_CATEGORY_TAB = '[Services] Set addon category tab';

export const DESELECT_SELECTED_ADDON = '[Services] Deselect selected addon';
export const SELECT_SERVICE = '[Services] Select service';
export const SELECT_SERVICE_SUCCESS = '[Services] Select service success';
export const UNSELECT_SERVICE = '[Services] Unselect service';
export const UNSELECT_SERVICE_SUCCESS = '[Services] Unselect service success';

export const CHANGE_SERVICE_STATUS = '[Services] Change service status';
export const CHANGE_SERVICE_STATUS_SUCCESS =
  '[Services] Change service status success';

export const CHANGE_ADDON_STATUS = '[Services] Change addon status';
export const CHANGE_ADDON_STATUS_SUCCESS =
  '[Services] Change addon status success';

export const DELETE_SERVICE = '[Services] Delete service';
export const DELETE_SERVICE_SUCCESS = '[Services] Delete service success';

export const DELETE_SELECTED_SERVICE_TIER =
  '[Services] Delete selected service tier';
export const DELETE_SELECTED_SERVICE_ADDON =
  '[Services] Delete selected service addon';
export const DELETE_SELECTED_SERVICE_ADDON_TIER =
  '[Services] Delete selected service addon tier';

export class GetServices implements Action {
  readonly type = GET_SERVICES;

  constructor() {}
}

export class GetServicesSuccess implements Action {
  readonly type = GET_SERVICES_SUCCESS;

  constructor(public petServices: IPetService[]) {}
}

export class ChangePetType implements Action {
  readonly type = CHANGE_PETTYPE;

  constructor(public petType: PetType | string) {}
}

export class CreateService implements Action {
  readonly type = CREATE_SERVICE;

  constructor(public petService: IPetService) {}
}

export class SelectServiceSuccess implements Action {
  readonly type = SELECT_SERVICE_SUCCESS;

  constructor(public petService: IPetService) {}
}

export class SelectService implements Action {
  readonly type = SELECT_SERVICE;

  constructor(public petServiceId: string) {}
}

export class UnselectService implements Action {
  readonly type = UNSELECT_SERVICE;

  constructor() {}
}

export class UnselectServiceSuccess implements Action {
  readonly type = UNSELECT_SERVICE_SUCCESS;

  constructor() {}
}

export class AddTierInSelectedService implements Action {
  readonly type = ADD_TIER_IN_SELECTED_SERVICE;

  constructor(public serviceTier: IPetServiceTier) {}
}

export class UpdateTierInSelectedService implements Action {
  readonly type = UPDATE_TIER_IN_SELECTED_SERVICE;

  constructor(public serviceTier: IPetServiceTier) {}
}

export class AddAddonInSelectedService implements Action {
  readonly type = ADD_ADDON_IN_SELECTED_SERVICE;

  constructor(public addon: IPetServiceAddon) {}
}

export class AddTierInSelectedAddonOfSelectedService implements Action {
  readonly type = ADD_TIER_IN_SELECTED_ADDON_OF_SELECTED_SERVICE;

  constructor(public addonTier: IPetServiceAddonTier) {}
}

export class UpdateTierInSelectedAddonOfSelectedService implements Action {
  readonly type = UPDATE_TIER_IN_SELECTED_ADDON_OF_SELECTED_SERVICE;

  constructor(public addonTier: IPetServiceAddonTier) {}
}

export class AddAddonTierInSelectedAddon implements Action {
  readonly type = ADD_ADDON_TIER_IN_SELECTED_ADDON;

  constructor(public addonTier: IPetServiceAddonTier) {}
}

export class DeleteSelectedServiceTier implements Action {
  readonly type = DELETE_SELECTED_SERVICE_TIER;

  constructor(public tier: IPetServiceTier) {}
}

export class DeleteSelectedServiceAddon implements Action {
  readonly type = DELETE_SELECTED_SERVICE_ADDON;

  constructor(public addon: IPetServiceAddon) {}
}

export class DeleteSelectedServiceAddonTier implements Action {
  readonly type = DELETE_SELECTED_SERVICE_ADDON_TIER;

  constructor(public tier: IPetServiceAddonTier) {}
}

export class SaveService implements Action {
  readonly type = SAVE_SERVICE;

  constructor(public petService: IPetService) {}
}

export class SaveServiceSuccess implements Action {
  readonly type = SAVE_SERVICE_SUCCESS;

  constructor(public petService: IPetService) {}
}

export class CreateServiceSuccess implements Action {
  readonly type = CREATE_SERVICE_SUCCESS;

  constructor(public petService: IPetService) {}
}

export class ChangeServiceStatus implements Action {
  readonly type = CHANGE_SERVICE_STATUS;

  constructor(public petServiceId: string | undefined) {}
}

export class ChangeServiceStatusSuccess implements Action {
  readonly type = CHANGE_SERVICE_STATUS_SUCCESS;

  constructor(public petServiceId: string | undefined) {}
}

export class ChangeAddonStatus implements Action {
  readonly type = CHANGE_ADDON_STATUS;

  constructor(
    public category: string,
    public newStatus: boolean,
    public petServiceId: string | undefined,
    public petServiceAddonId: string | undefined
  ) {}
}

export class SetAddonCategoryTab implements Action {
  readonly type = SET_ADDON_CATEGORY_TAB;
  constructor(public category: string) {
  }
}

export class SelectAddon implements Action {
  readonly type = SELECT_ADDON;

  constructor(public addon: IPetServiceAddon) {}
}

export class SelectAddonTier implements Action {
  readonly type = SELECT_ADDON_TIER;

  constructor(public addonTier: IPetServiceAddonTier) {}
}

export class DeselectSelectedAddon implements Action {
  readonly type = DESELECT_SELECTED_ADDON;

  constructor() {}
}

export class ChangeAddonStatusSuccess implements Action {
  readonly type = CHANGE_ADDON_STATUS_SUCCESS;

  constructor(
    public petServiceId: string | undefined,
    public petServiceAddonId: string | undefined
  ) {}
}

export class DeleteService implements Action {
  readonly type = DELETE_SERVICE;

  constructor(public petServiceId: string | undefined) {}
}

export class DeleteServiceSuccess implements Action {
  readonly type = DELETE_SERVICE_SUCCESS;

  constructor(public petServiceId: string | undefined) {}
}

export type Actions =
  | GetServices
  | GetServicesSuccess
  | ChangePetType
  | DeleteService
  | CreateServiceSuccess
  | CreateService
  | DeleteServiceSuccess
  | ChangeServiceStatus
  | ChangeServiceStatusSuccess
  | SelectService
  | SelectServiceSuccess
  | UnselectService
  | UnselectServiceSuccess
  | DeleteSelectedServiceTier
  | DeleteSelectedServiceAddon
  | DeleteSelectedServiceAddonTier
  | SaveService
  | SaveServiceSuccess
  | ChangeAddonStatusSuccess
  | ChangeAddonStatus
  | SelectAddon
  | SelectAddonTier
  | DeselectSelectedAddon
  | AddTierInSelectedService
  | UpdateTierInSelectedService
  | AddAddonInSelectedService
  | AddTierInSelectedAddonOfSelectedService
  | UpdateTierInSelectedAddonOfSelectedService
  | AddAddonTierInSelectedAddon
  | SetAddonCategoryTab;
