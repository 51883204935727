import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '../state/app.state';
import { Store } from '@ngrx/store';
import { getPage } from './state/home.selector';
import { SetPage } from './state/home.action';
import { CalendarComponent } from './calendar/calendar.component';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'romeo-grooming-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  @Input() selectedPage$: Observable<string>;
  @ViewChild('drawer', { static: true }) drawer!: MatDrawer;

  public sidenavTemplate!: TemplateRef<any>;
  public sidenavWidth!: string;
  public sidenavTemplateParams: any;
  public showSidenav: boolean = false;

  constructor(public store: Store<AppState>) {
    this.selectedPage$ = this.store.select(getPage);
  }

  ngOnInit(): void {}

  navigate(url: string): void {
    this.store.dispatch(new SetPage(url));
  }

  getActivePage(page: string | null): string {
    if (!page) {
      return '/';
    }

    if (page.indexOf('settings') > -1) {
      return 'settings';
    }

    if (page.indexOf('customers') > -1) {
      return 'customers';
    }

    if (page.indexOf('operators') > -1) {
      return 'operators';
    }

    if (page.indexOf('appointments') > -1) {
      return 'appointments';
    }

    if (page.indexOf('calendar') > -1) {
      return 'calendar';
    }

    return '/';
  }

  onActivate(elementRef: CalendarComponent): void {
    if (elementRef instanceof CalendarComponent) {
      elementRef.createdNewAppointment.subscribe(
        (sidenav: {
          template: TemplateRef<any>;
          params: any;
          width: string;
        }) => {
          this.showSidenav = !this.showSidenav;
          this.sidenavTemplate = sidenav.template;
          this.sidenavTemplateParams = sidenav.params;
          this.sidenavWidth = sidenav.width;
          this.drawer.toggle();
        }
      );

      elementRef.closeNewAppointment.subscribe(() => {
        this.showSidenav = false;
        this.drawer.toggle();
      });
    }
  }
}
