import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IApiResponse } from './models/i-api-response';
import { catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public API_ENDPOINT: string | undefined = undefined;
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  set apiEndpoint(apiEndpoint: string | undefined) {
    this.API_ENDPOINT = apiEndpoint;
  }

  get apiEndpoint(): string | undefined {
    return this.API_ENDPOINT;
  }

  get(
    url: string,
    options?: { headers?: HttpHeaders; params?: HttpParams }
  ): Observable<IApiResponse> {
    if (this.apiEndpoint === undefined) {
      throw new Error('Set apiEndpoint before use this service');
    }

    const extendedHeaders: HttpHeaders = Object.assign(
      this.headers,
      options?.headers
    ) as HttpHeaders;
    return this.http
      .get(this.apiEndpoint + url, {
        headers: extendedHeaders,
        params: options?.params,
      })
      .pipe(
        map((res: any) => res as IApiResponse),
        catchError((err, caught) => {
          this.snackBar.open(err.error.message, '', {
            duration: 2000,
            panelClass: ['error-api'],
          });
          return of(err.ok);
        })
      );
  }

  post(
    url: string,
    body: any,
    headers?: HttpHeaders
  ): Observable<IApiResponse> {
    if (this.apiEndpoint === undefined) {
      throw new Error('Set apiEndpoint before use this service');
    }
    const extendedHeaders: HttpHeaders = Object.assign(
      this.headers,
      headers
    ) as HttpHeaders;
    return this.http
      .post(this.apiEndpoint + url, body, {
        headers: extendedHeaders,
      })
      .pipe(
        map((res: any) => res as IApiResponse),
        catchError((err, caught) => {
          this.snackBar.open(err.error.message, '', {
            duration: 2000,
            panelClass: ['error-api'],
          });
          return of(err.ok);
        })
      );
  }

  patch(
    url: string,
    body: any,
    headers?: HttpHeaders
  ): Observable<IApiResponse> {
    if (this.apiEndpoint === undefined) {
      throw new Error('Set apiEndpoint before use this service');
    }
    const extendedHeaders: HttpHeaders = Object.assign(
      this.headers,
      headers
    ) as HttpHeaders;
    return this.http
      .patch(this.apiEndpoint + url, body, {
        headers: extendedHeaders,
      })
      .pipe(
        map((res: any) => res as IApiResponse),
        catchError((err, caught) => {
          this.snackBar.open(err.error.message, '', {
            duration: 2000,
            panelClass: ['error-api'],
          });
          return of(err.ok);
        })
      );
  }

  put(url: string, body: any, headers?: HttpHeaders): Observable<IApiResponse> {
    if (this.apiEndpoint === undefined) {
      throw new Error('Set apiEndpoint before use this service');
    }
    const extendedHeaders: HttpHeaders = Object.assign(
      this.headers,
      headers
    ) as HttpHeaders;
    return this.http
      .put(this.apiEndpoint + url, body, {
        headers: extendedHeaders,
      })
      .pipe(
        map((res: any) => res as IApiResponse),
        catchError((err, caught) => {
          this.snackBar.open(err.error.message, '', {
            duration: 2000,
            panelClass: ['error-api'],
          });
          return of(err.ok);
        })
      );
  }

  delete(
    url: string,
    options?: { headers?: HttpHeaders; params?: HttpParams }
  ): Observable<IApiResponse> {
    if (this.apiEndpoint === undefined) {
      throw new Error('Set apiEndpoint before use this service');
    }
    const extendedHeaders: HttpHeaders = Object.assign(
      this.headers,
      options?.headers
    ) as HttpHeaders;
    return this.http
      .delete(this.apiEndpoint + url, {
        headers: extendedHeaders,
        params: options?.params,
      })
      .pipe(
        map((res: any) => res as IApiResponse),
        catchError((err, caught) => {
          this.snackBar.open(err.error.message, '', {
            duration: 2000,
            panelClass: ['error-api'],
          });
          return of(err.ok);
        })
      );
  }
}
