<span class="calendar_page__header">
  <h1 class="title">Calendario</h1>
  <span class="calendar_nav_controls">
    <mat-icon class="calendar_nav_controls__prev"
              (click)="prev()">chevron_left</mat-icon>
    <mat-icon class="calendar_nav_controls__next"
              (click)="next()">
      chevron_right</mat-icon>
    <span>
      <span class="calendar_nav_controls__date_month">
        {{calendar.selectedDate | date:(calendarOptions.viewType === 'daily' ? 'd MMM' : 'MMM') | titlecase}}.
      </span>
      <span class="calendar_nav_controls__date_year">
        {{calendar.selectedDate | date:'YYYY'}}
      </span>
    </span>
  </span>
  <mat-button-toggle-group name="calendar-viewType"
                         [value]="calendarOptions.viewType"
                         class="calendar-viewType">
    <mat-button-toggle value="daily" (click)="calendarOptions.viewType = 'daily'; sidenavAppointmentIsOpened ? null : fetchDatas(calendarOptions.selectedDate, calendarOptions.viewType, filters);">Giorno</mat-button-toggle>
    <mat-button-toggle value="weekly" (click)="calendarOptions.viewType = 'weekly'; sidenavAppointmentIsOpened ? null : fetchDatas(calendarOptions.selectedDate, calendarOptions.viewType, filters);">Settimana</mat-button-toggle>
    <mat-button-toggle value="monthly" (click)="calendarOptions.viewType = 'monthly'; sidenavAppointmentIsOpened ? null : fetchDatas(calendarOptions.selectedDate, calendarOptions.viewType, filters);">Mese</mat-button-toggle>
    <mat-button-toggle value="list" [disabled]="listviewDisabled" [style.pointerEvents]="listviewDisabled ? 'none' : 'all'" (click)="calendarOptions.viewType = 'list'; sidenavAppointmentIsOpened ? null : fetchDatas(calendarOptions.selectedDate, calendarOptions.viewType, filters);">Programmati</mat-button-toggle>
  </mat-button-toggle-group>
</span>
<span class="calendar-container">
  <span class="filter-area">
    <span class="filters">
      <span class="filters__text">Appuntamenti</span>
      <span class="filter-row exited">
        <span class="exited_text">Esitati</span>
        <mat-slide-toggle [checked]="filters.appointments.exited"
                          class="filter-action"
                          (change)="filterAppointmentChanged({exited: $event.checked})"></mat-slide-toggle>
      </span>
      <span class="filter-row to_be_exited">
        <span class="to_be_exited_text">Da Esitare <span class="to_be_exited_number">{{appointmentsService?.toBeExited$ | async}}</span></span>
        <mat-slide-toggle [checked]="filters.appointments.toBeExited"
                          class="filter-action"
                          (change)="filterAppointmentChanged({toBeExited: $event.checked})"></mat-slide-toggle>
      </span>
    </span>
    <span class="filters">
      <span class="filters__text">Collaboratori</span>
      <span class="filter-row" *ngFor="let operator of appointmentsService?.operators$ | async">
        <span class="exited_text">{{operator.firstName}} {{operator.lastName}}</span>
        <mat-slide-toggle [checked]="!(filters.operatorsToExclude.indexOf(operator.id) > -1)"
                          class="filter-action"
                          (change)="filterOperatorChanged({id: operator.id, checked: $event.checked})"></mat-slide-toggle>
      </span>
    </span>
    <span class="legend-area" *ngIf="calendarOptions.viewType === 'monthly'">
      <span class="legend">
         <span class="legend__freeHours"> </span>
        <span class="legend__text">Ore libere</span>
      </span>
      <span class="legend">
        <span class="legend__customers"> </span>
        <span class="legend__text">Clienti</span>
      </span>
    </span>
  </span>
  <lib-calendar
    #calendar
    class="calendar"
    [daysOff]="calendarOptions.daysOff"
    [events]="events"
    [viewType]="calendarOptions.viewType"
    [timeSlotConfigs]="calendarOptions.timeslotConfigs"
    [eventsConfigs]="calendarOptions.eventsConfigs"
    [selectedDate]="calendarOptions.selectedDate"></lib-calendar>
</span>
<button mat-fab color="accent" class="new"
        (click)="!sidenavAppointmentIsOpened ? openSidenavAppointment() : closeSidenavAppointment()"
        [style.bottom]="'30px'">
  <mat-icon>add</mat-icon>
</button>

<ng-template #monthlyFreeTimeslotTemplate let-data>
  <span class="monthly-freetimeslot" (click)="selectDate(data.date)">
     <span class="monthly-freetimeslot-box" *ngIf="data">
       <mat-icon class="monthly-freetimeslot-icon">check</mat-icon>
    </span>
  </span>
</ng-template>

<ng-template #monthlyEventTemplate let-data>
  <span class="monthly-event">
    <span  *ngIf="data?.props">
      <div class="free-hours">
        <span>{{data.props.freeHours}}</span>
        <div class="free-hours-progress" [style.width]="(data.props.freeHours / data.props.totalHours * 100) + '%'"></div>
      </div>
      <div class="customers">
        <span>{{data.props.customers}}</span>
        <div class="customers-progress" [style.width]="(sumOperatorsHours(data.props.operators) / higherCustomerLoad * 100) + '%'"></div>
      </div>
      <div class="operators">
        <div class="operator"
             *ngFor="let operator of data.props.operators"
             [style.color]="operator.operator.color">
          <span>{{operator.workload}}</span>
          <div class="operator-progress" [style.backgroundColor]="operator.operator.color" [style.width]="(operator.workload / higherCustomerLoad * 100) + '%'"></div>
        </div>
      </div>
    </span>
  </span>
</ng-template>

<ng-template #weeklyEventTemplate let-data>
  <span class="weekly-freetimeslot" *ngIf="data.props.free" (click)="selectDate(data.date, data.startTime, data.endTime, data.props.services, data.id)">
     <span class="weekly-freetimeslot-box" *ngIf="data">
      {{data.startTime | date:'HH:mm'}} - {{data.endTime | date:'HH:mm'}}
     </span>
      <mat-icon [ngClass]="'weekly-freetimeslot-icon' + (newAppointmentForm.controls.id.value == data.id ? '-selected' : '')">check</mat-icon>
  </span>
  <span class="weekly-event" *ngIf="!data.props.free" (click)="selectEvent(data)" >
    <span *ngIf="data?.props">
      <span class="weekly-box"
        [style.background]="getColorWithAlpha(data.props.operator.color, 0.3)"
        [style.color]="data.props.color"
        [style.borderLeft]="'4px solid ' + data.props.operator.color">
        <div class="weekly-timerange">{{data.startTime | date:'HH:mm'}} - {{data.endTime | date:'HH:mm'}}</div>
        <div class="customer-name">{{data.props.owner.firstName + ' ' + data.props.owner.lastName}}</div>
      </span>
    </span>
  </span>
</ng-template>

<span class="context-menu" #contextMenu
      [style.display]="!contextMenuIsVisible ? 'none' : 'block'"
      [style.borderLeft]="'4px solid ' + selectedEvent?.props?.operator.color">
  <span class="context-menu__toolbar">
    <span class="edit context-menu__toolbar__item" (click)="editAppointment(selectedEvent)">
      <mat-icon>edit</mat-icon>
    </span>
    <span class="owner context-menu__toolbar__item" (click)="router.navigate(['customers', selectedEvent?.props?.owner?.id, 'owner'])">
      <mat-icon>person</mat-icon>
    </span>
    <span class="register context-menu__toolbar__item" (click)="(selectedEvent?.props.registered ? null : router.navigate(['appointments', selectedEvent?.id, 'register', 'appointment']))">
      <mat-icon>check</mat-icon>
    </span>
    <span class="delete context-menu__toolbar__item" (click)="deleteAppointment(selectedEvent)">
      <mat-icon>delete</mat-icon>
    </span>
  </span>
  <span class="context-menu__container">
    <span class="context-menu__informations">
       <span class="context-menu__informations__customer-name">
          {{selectedEvent?.props?.owner?.firstName }} {{selectedEvent?.props?.owner?.lastName}} <span class="context-menu__informations__pet-name">({{selectedEvent?.props?.pet?.name}})</span>
       </span>
       <span class="row">
          <span class="context-menu__informations__data">
            {{selectedEvent?.date | date:'EEEE, d MMMM' | titlecase}} <span class="dot-divisor">-</span> {{selectedEvent?.startTime | date:'HH:mm'}} - {{selectedEvent?.endTime | date:'HH:mm'}}
          </span>
          <span class="context-menu__informations__price">{{servicesService.computeTotalAmount(selectedEvent?.props?.services)}} €
          </span>
       </span>
    </span>
    <span class="context-menu__more-informations">
      <span *ngIf="selectedEvent?.props?.firstAppointmentPet?.inThisGrooming"> Primo appuntamento animale in questa toelettatura</span>
      <span *ngIf="selectedEvent?.props?.firstAppointmentPet?.inEveryOtherGrooming"> Primo appuntamento animale in qualsiasi toelettatura</span>
      <span *ngIf="selectedEvent?.props?.firstAppointmentOwner?.inThisGrooming"> Primo appuntamento cliente in questa toelettatura</span>
      <span *ngIf="selectedEvent?.props?.firstAppointmentOwner?.inEveryOtherGrooming"> Primo appuntamento cliente in qualsiasi toelettatura</span>
    </span>
    <span class="context-menu__services">
      <span class="context-menu__services__service" *ngFor="let service of selectedEvent?.props?.services">
        {{service.name}}
        <span class="context-menu__services__service__addons">
          <span class="context-menu__services__service__addon" *ngFor="let addon of service.addons">
            + {{addon.name}}
          </span>
        </span>
      </span>
    </span>
    <span class="context-menu__notes">
    {{selectedEvent?.props?.bookingNotes}}
    </span>
    <span class="context-menu__operator">
    <span class="row">
      <span class="context-menu__operator__name">
        {{selectedEvent?.props?.operator?.firstName}} {{selectedEvent?.props?.operator?.lastName}}
      </span>
      <span class="context-menu__operator__table">
        Tavolo: {{selectedEvent?.props?.table}}
      </span>
    </span>
    <span class="context-menu__operator__table">

    </span>
  </span>
  </span>
</span>

<ng-template #listEventTemplate let-data>
  <span [ngClass]="'list-box ' + (data.props.registered ? 'registered' : '')"
        (click)="selectEvent(data)"
        [style.borderLeft]="'5px solid ' + data.props.operator.color">
    <div class="list-box__timerange">{{data.startTime | date:'HH:mm'}} - {{data.endTime | date:'HH:mm'}}</div>
    <div class="list-box__customerandpets">
        <span class="list-box__customername">{{data.props.owner.firstName + ' ' + data.props.owner.lastName}}, </span>
        <span class="list-box__petsnames">{{data.props.pet.name}}</span>
    </div>
    <div class="list-box__servicesname">
      <span *ngFor="let service of data.props.services; let i = index">
        {{service.name + (data.props.services.length - 1 > i ? ',' : '')}}
      </span>
    </div>
  </span>
  <span class="list-box__registered {{(data.props.registered ? 'registered' : '')}}"
        (click)="(data.props.registered ? null : router.navigate(['appointments', data.id, 'register', 'appointment']))">
    <span [ngClass]="'list-box__registered__icon' + (data.props.registered ? '-active' : '-inactive')">
      <mat-icon class="list-box__registered__icon__icon">check</mat-icon>
    </span>
  </span>
</ng-template>


<ng-template #createAppointmentTemplate let-data>
  <span class="create-appointment__header">
    <mat-icon class="modal-close create-appointment__close"
              (click)="closeSidenavAppointment()">close</mat-icon>
    <h1 class="title create-appointment__title">Aggiungi Appuntamento</h1>
  </span>
  <romeo-grooming-appointment
    *ngIf="sidenavAppointmentIsOpened"
    (onSearchCustomer)="fetchCustomers($event)"
    [services]="services"
    [customers$]="customers$"
    (change)="newAppointmentFormChanged($event)"
    [operators]="operators"
    [newAppointmentForm]="newAppointmentForm">
  </romeo-grooming-appointment>
  <button class="btn-modal create-appointment__btn"
          [disabled]="newAppointmentForm.invalid || formContainErrors"
          (click)="createNewAppointment()"
          color="primary" mat-flat-button>
    Prenota
  </button>
</ng-template>


<ng-template #removeAppointmentModal let-data>
  <mat-icon class="modal-close" [mat-dialog-close]="null">close</mat-icon>
  <h1 class="modal-title text-center" mat-dialog-title>Elimina appuntamento</h1>
  <div class="modal-content" mat-dialog-content>
    <p>Sei sicuro di voler eliminarlo dai tuoi appuntamenti?</p>
  </div>
  <div mat-dialog-actions class="modal-buttons">
    <button class="danger" mat-raised-button [mat-dialog-close]="data">Elimina</button>
  </div>
</ng-template>
