import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ICalendarEvent } from '../models/i-calendar-event';
import { CalendarService } from '../calendar.service';
import { TranslateService } from '@ngx-translate/core';
import { CalendarViewType } from '../models/calendar-view-type';

@Component({
  selector: 'lib-calendar-event-area',
  templateUrl: './calendar-event-area.component.html',
  styleUrls: ['./calendar-event-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarEventAreaComponent implements OnInit {
  @Input() events: Array<ICalendarEvent> = [];
  @Input() viewType: CalendarViewType | string = CalendarViewType.weekly;
  @Input() timeslotSizeHourInPixel = 23;
  @Input() startTime = 0;
  @Input() endTime = 1000;
  @Input() eventTemplate!: TemplateRef<any>;

  constructor(
    public calendarService: CalendarService,
    public cdr: ChangeDetectorRef,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  getTopPosition(event: ICalendarEvent) {
    const hours = event.startTime.getHours() - this.startTime / 60;
    return (
      hours * (this.timeslotSizeHourInPixel + 2) +
      (hours + (this.viewType === CalendarViewType.weekly ? 2 : 1)) +
      'px'
    );
  }

  getHeight(event: ICalendarEvent) {
    const hours =
      event.endTime.getHours() -
      event.startTime.getHours() +
      (event.endTime.getMinutes() - event.startTime.getMinutes()) / 60;
    return hours * (this.timeslotSizeHourInPixel + hours) - hours + 'px';
  }
}
