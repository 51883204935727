<form [formGroup]="newAppointmentForm"
      class="form main-form"
      (input)="change.emit({ change: newAppointmentForm.getRawValue(), errors: newAppointmentForm.invalid}); newAppointmentForm.clearValidators();">
      <mat-form-field floatLabel="always"
                      appearance="outline">
        <mat-label>Nome e cognome</mat-label>
        <input matInput
               [matAutocomplete]="autocompleteCustomer"
               required
               #inputSearchCustomers>
        <button *ngIf="inputSearchCustomers.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="inputSearchCustomers.value=''; inputSearchPet.value=''; newAppointmentForm.controls.customer.setValue(''); newAppointmentForm.controls.pet.setValue('');">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete autoActiveFirstOption
                          #autocompleteCustomer="matAutocomplete">
          <mat-option *ngFor="let customer of customers$ | async"
                      (click)="changeCustomer(customer.id!, customer)"
                      value="{{customer.firstName + ' ' + customer.lastName}}, {{customer.phoneNumber}}">
            {{customer.firstName + ' ' + customer.lastName}}, {{customer.phoneNumber}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="newAppointmentForm ? (newAppointmentForm.controls['customer'].hasError('required')) : false">{{'Il campo è obbligatorio'}}</mat-error>
      </mat-form-field>
      <mat-form-field floatLabel="always"
                    appearance="outline">
      <mat-label>Animale</mat-label>
      <input matInput
             [disabled]="!newAppointmentForm.controls['customer'].value"
             [matAutocomplete]="autocompletePet"
             #inputSearchPet
             required>
        <button *ngIf="inputSearchPet.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="inputSearchPet.value=''; newAppointmentForm.controls.pet.setValue(''); emitError()">
          <mat-icon>close</mat-icon>
        </button>
      <mat-autocomplete autoActiveFirstOption
                        #autocompletePet="matAutocomplete">
        <mat-option *ngFor="let pet of newAppointmentForm.controls['customer'].value?.pets"
                    (click)="changePet(pet.id!, pet)"
                    value="{{pet.name}}, {{pet.breed.name}}">
          {{pet.name}}, {{pet.breed.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="newAppointmentForm ? (newAppointmentForm.controls['pet'].hasError('required')) : false">{{'Il campo è obbligatorio'}}</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always"
                      appearance="outline">
        <mat-label>Primo appuntamento</mat-label>
        <input matInput
               [disabled]="true"
               [value]="'Animale'">
        <mat-list>
          <mat-list-item>
            <mat-checkbox class=""
                          [formControlName]="'firstAppointmentPetInThisGrooming'">
              In questa toelettatura
            </mat-checkbox>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <mat-checkbox class=""
                          [formControlName]="'firstAppointmentPetInEveryOtherGrooming'">
              In qualsiasi toelettatura
            </mat-checkbox>
          </mat-list-item>
          <mat-divider></mat-divider>
        </mat-list>
        <input matInput
               [disabled]="true"
               [value]="'Cliente'">
        <mat-list>
          <mat-list-item>
            <mat-checkbox class=""
                          [formControlName]="'firstAppointmentOwnerInThisGrooming'">
              In questa toelettatura
            </mat-checkbox>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <mat-checkbox class=""
                          [formControlName]="'firstAppointmentOwnerInEveryOtherGrooming'">
              In qualsiasi toelettatura
            </mat-checkbox>
          </mat-list-item>
          <mat-divider></mat-divider>
        </mat-list>
        <mat-error *ngIf="newAppointmentForm ? (newAppointmentForm.controls['pet'].hasError('required')) : false">{{'Il campo è obbligatorio'}}</mat-error>
      </mat-form-field>
    <mat-form-field floatLabel="always"
                    [style.marginBottom]="utilsService.isEmptyObject(servicesSelected[servicesSelected.length - 1]) ? '0px' : '15px'"
                    *ngFor="let service of servicesSelected; let i = index"
                    appearance="outline">
      <mat-label>Servizio</mat-label>
      <input matInput
                 [value]="service.name ? service.name : ''"
                 [matAutocomplete]="autocompleteService"
                 [disabled]="service.name"
                 required>
      <button *ngIf="service.name" matSuffix mat-icon-button aria-label="Clear"
              (click)="removeService(service)">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete autoActiveFirstOption
                        #autocompleteService>
        <mat-option *ngFor="let service of services"
                    (click)="addService(service.id!, service)"
                    value="{{service.name}}">
          {{service.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-list>
        <mat-list-item *ngFor="let addon of addonsSelected[i]">
          <mat-divider></mat-divider>
          <mat-checkbox class="">
            {{addon.name}}
          </mat-checkbox>
        </mat-list-item>
      </mat-list>
      <mat-error *ngIf="newAppointmentForm ? (newAppointmentForm.controls['pet'].hasError('required')) : false">{{'Il campo è obbligatorio'}}</mat-error>
    </mat-form-field>
  <span class="add-service-button {{utilsService.isEmptyObject(servicesSelected[servicesSelected.length - 1]) ? 'disabled' : ''}}"
          (click)="addBlankServiceService()">
    <mat-icon class="add-service-button__icon" >add</mat-icon>
    <span class="add-service-button__text">{{'add_another_service' | translate}}</span>
  </span>

  <mat-form-field
    floatLabel="always"
    appearance="outline">
    <mat-label>Note</mat-label>
    <textarea matInput
              [rows]="4"
              [formControlName]="'bookingNotes'"
              placeholder=""></textarea>
  </mat-form-field>

  <mat-form-field floatLabel="always"
                  appearance="outline">
    <mat-label>Quando</mat-label>
    <input matInput
           [style.display]="'none'"
           [disabled]="true">
    <mat-list>
      <mat-list-item class="filter">
        Mattina
        <mat-slide-toggle
          [checked]="filters.morning">
        </mat-slide-toggle>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item class="filter">
        Pomeriggio
        <mat-slide-toggle
          [checked]="filters.afternoon">
        </mat-slide-toggle>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </mat-form-field>

  <span class="operators-tables mat-form-field">
    <mat-form-field floatLabel="always"
                     class="operators"
                     appearance="outline">
    <mat-label>Operatore</mat-label>
    <input matInput
           [style.display]="utilsService.isEmptyObject(newAppointmentForm.controls.operator.value) ? '' : 'none'"
           [matAutocomplete]="autocompleteOperator"
           #inputSearchOperator
           required>
      <span class="custom-option" *ngIf="!utilsService.isEmptyObject(newAppointmentForm.controls.operator.value)">
        <span [style.backgroundColor]="newAppointmentForm.controls.operator.value?.color" class="color-box"></span>
        <span class="capitalize">{{newAppointmentForm.controls.operator.value?.fullName}}</span>
      </span>
      <button *ngIf="!utilsService.isEmptyObject(newAppointmentForm.controls.operator.value)" matSuffix mat-icon-button class="operators-clear" aria-label="Clear"
              (click)="inputSearchOperator.value=''; newAppointmentForm.controls.operator.setValue({}); emitError();">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete autoActiveFirstOption
                        #autocompleteOperator="matAutocomplete">
        <mat-option *ngFor="let operator of operators"
                    (click)="changeOperator(operator.id!, operator)">
           <span class="custom-option" *ngIf="utilsService.isEmptyObject(newAppointmentForm.controls.operator.value) || newAppointmentForm.controls.operator.value == ''">
            <span [style.backgroundColor]="operator?.color" class="color-box"></span>
            <span class="capitalize">{{operator?.fullName}}</span>
          </span>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="newAppointmentForm ? (newAppointmentForm.controls['operator'].hasError('required')) : false">{{'Il campo è obbligatorio'}}</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always"
                        class="tables"
                        appearance="outline">
      <mat-label>Tavolo</mat-label>
      <input matInput
             [matAutocomplete]="autocompleteTables"
             #inputSearchOperator
             required>
      <mat-autocomplete autoActiveFirstOption
                        #autocompleteTables="matAutocomplete">
        <mat-option *ngFor="let table of tables"
                    (click)="changeTable(table.id!, table)"
                    value="{{table.number}}">
        {{table.number}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="newAppointmentForm ? (newAppointmentForm.controls['table'].hasError('required')) : false">{{'Il campo è obbligatorio'}}</mat-error>
    </mat-form-field>
  </span>
  <span class="duration-price mat-form-field">
     <mat-form-field floatLabel="always" appearance="outline" class="duration">
        <mat-label>{{'duration_expected' | translate}}</mat-label>
        <input matInput required type="time"
               formControlName="duration">
    <mat-error *ngIf="newAppointmentForm ? (newAppointmentForm.controls['duration'].hasError('required')) : false">{{'Il campo è obbligatorio'}}</mat-error>
    </mat-form-field>
     <mat-form-field floatLabel="always" appearance="outline" class="price">
          <mat-label>{{'price_expected' | translate}}</mat-label>
          <input matInput required class="price-chooser"
                 [formControlName]="'totalAmount'" [min]="0.1" [step]="0.1" type="number">
     <mat-error *ngIf="newAppointmentForm ? (newAppointmentForm.controls['totalAmount'].hasError('required')) : false">{{'Il campo è obbligatorio'}}</mat-error>
    </mat-form-field>
  </span>


  <mat-form-field
    class="disabled"
    floatLabel="always"
    appearance="outline">
    <mat-label>Giorno</mat-label>
    <input matInput required [matDatepicker]="picker"
           [formControlName]="'date'">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="newAppointmentForm ? (newAppointmentForm.controls['date'].hasError('required')) : false">{{'Il campo è obbligatorio'}}</mat-error>
  </mat-form-field>

  <span class="from-to mat-form-field disabled">
     <mat-form-field floatLabel="always" appearance="outline" class="from">
        <mat-label>Dalle</mat-label>
        <input matInput required type="time"
               formControlName="startTime">
    </mat-form-field>
    <mat-form-field floatLabel="always" appearance="outline" class="to">
        <mat-label>Alle</mat-label>
        <input matInput required type="time"
               formControlName="endTime">
    </mat-form-field>
  </span>
</form>
